import Avatar, { AvatarGroupProps } from "@/components/primitives/avatar";

export type PickGroupProps = Omit<AvatarGroupProps, 'value'> & { totalPicksForSale: number; totalBettors: number };

function PickGroup(
  props: PickGroupProps
) {
  const { avatars, dark, side, label, className, ...rest } = props;
const { totalBettors, totalPicksForSale, ...filteredRest } = rest;
  return (
    <Avatar.Group
    {...filteredRest}
      avatars={props.avatars}
      label={`${props.totalPicksForSale} picks for sale`}
      value={props.totalBettors}
    />
  );
}

export default PickGroup;

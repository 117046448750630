"use client";
import React, { createContext, useContext, useState, useEffect } from "react";
import { ApiResponse, Scoreboard, ScoreboardGame } from "common";
import {
  GameCounts,
  GamesBySport,
  GamesByStatus,
  buildScoreboardRequest,
  calculateGameCounts,
  defaultGameCounts,
  defaultGamesByStatus,
  groupGamesBySport2,
  groupGamesByStatus2,
} from "../game";
import { useScoreboardUpdate } from "@/utils/hooks/scoreboard-update";
import {
  convertDateToStringWithoutTime,
  formatDate,
  getCurrentDateWithPadding,
} from "../date";
import { usePathname } from "next/navigation";
import { useWebSocketContext } from "./websocket";
import mlClient from "@/ml-client";

interface ScoreboardContextType {
  games: ScoreboardGame[] | null | undefined;
  gameCounts: GameCounts;
  gamesBySport: GamesBySport;
  gamesByStatus: GamesByStatus;
  isLoading: boolean;
  isError: boolean;
  error: string | null;
  dateOptions: Option[];
  selectedDate: string | undefined;
  setSelectedDate: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ScoreboardContext = createContext<ScoreboardContextType>({
  games: undefined,
  gameCounts: defaultGameCounts(),
  gamesBySport: {},
  gamesByStatus: defaultGamesByStatus(),
  isLoading: true,
  isError: false,
  error: null,
  dateOptions: [],
  selectedDate: undefined,
  setSelectedDate: () => {},
});

export const useScoreboard = () => {
  const context = useContext(ScoreboardContext);
  if (!context) {
    throw new Error("useScoreboard must be used within a ScoreboardProvider");
  }
  return context;
};

export function ScoreboardProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname();
  const { isConnected } = useWebSocketContext();
  const [games, setGames] = useState<ScoreboardGame[]>([]);
  const [gamesByStatus, setGamesByStatus] = useState<GamesByStatus>(
    defaultGamesByStatus()
  );
  const [gamesBySport, setGamesBySport] = useState<GamesBySport>({});
  const [gameCounts, setGameCounts] = useState<GameCounts>(defaultGameCounts());
  const [today, setToday] = React.useState<Date | undefined>(undefined);
  const [selectedDate, setSelectedDate] = React.useState<string | undefined>(
    undefined
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async (wsConnected: boolean = false) => {
    if (!request) return;
    try {
      if (!wsConnected) {
        setIsLoading(true);
      }
      setIsError(false);
      const response = await mlClient.get(request);
      if (response.data) {
        const games = response.data.data.games;
        setGames(games);
        setGamesBySport(groupGamesBySport2(games));
        setGamesByStatus(groupGamesByStatus2(games));
        setGameCounts(calculateGameCounts(games));
      }
    } catch (err) {
      setIsError(true);
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
    } finally {
      if (!wsConnected) setIsLoading(false);
    }
  };

  useScoreboardUpdate(games, setGames);

  // Set today's date on mount so date options can be built.
  // Also triggers if the route changes.
  useEffect(() => {
    const now = new Date();
    const nowAdjusted = getCurrentDateWithPadding(5);
    setToday(now);
    const formattedToday = convertDateToStringWithoutTime(nowAdjusted);
    setSelectedDate(formattedToday);
  }, [pathname]);


  const dateOptions = React.useMemo(() => {
    if (!today) return [];
    return createDateOptions(today);
  }, [today]);

  function createDateOptions(inputDate?: Date): Option[] {
    const baseDate = inputDate ? new Date(inputDate) : new Date();
    baseDate.setHours(0, 0, 0, 0);
    const yesterday = new Date(baseDate);
    yesterday.setDate(yesterday.getDate() - 1);

    const dateOptions: Option[] = [
      {
        label: "Yesterday",
        value: convertDateToStringWithoutTime(yesterday),
        index: 0,
      },
      {
        label: "Today",
        value: convertDateToStringWithoutTime(baseDate),
        index: 1,
      },
    ];

    const getNextDays = (count: number): Option[] => {
      const nextDateOptions: Option[] = [];
      for (let i = 1; i <= count; i++) {
        const nextDay = new Date(baseDate);
        nextDay.setDate(baseDate.getDate() + i);
        const option: Option = {
          label: formatDate(nextDay.toISOString(), true),
          value: convertDateToStringWithoutTime(nextDay),
          index: i + 1,
        };
        nextDateOptions.push(option);
      }
      return nextDateOptions;
    };
    return [...dateOptions, ...getNextDays(6)];
  }

  const request: string = React.useMemo(() => {
    if (!selectedDate || !today) return "";
    return buildScoreboardRequest("all", selectedDate, false);
  }, [selectedDate, today]);

  // fetch data once on mount with a loading placeholder.
  useEffect(() => {
    fetchData();
  }, [request]);

  // fetch data whenever the websocket connects without a loading placeholder.
  useEffect(() => {
    fetchData(true);
  }, [isConnected]);

  return (
    <ScoreboardContext.Provider
      value={{
        games,
        gameCounts,
        gamesBySport,
        gamesByStatus,
        isLoading,
        isError,
        error,
        dateOptions,
        selectedDate,
        setSelectedDate,
      }}
    >
      {children}
    </ScoreboardContext.Provider>
  );
}

"use client";
import { useSettings } from "@/utils/providers/settings";
import { useUser } from "@clerk/nextjs";
import Link from "next/link";
import React from "react";

export default function HowMoneylineWorks() {
  const settings = useSettings();
  const { isLoaded } = useUser();
  const [showBanner, setShowBanner] = React.useState(true);

  React.useEffect(() => {
    if (isLoaded) {
      const initialBannerStatus = checkBannerStatus();
      setShowBanner(initialBannerStatus);
    }
  }, [isLoaded]);


  React.useEffect(() => {
    if (!settings.loading && settings.settings && !settings.settings.createdFirstPick) {
      setShowBanner(checkBannerStatus());
    }
  }, [settings, isLoaded]);



  const checkBannerStatus = () => {
    const bannerClicked = localStorage.getItem('hmwBannerClicked');
    return bannerClicked !== 'true';
  };

  const handleClick = () => {
    localStorage.setItem('hmwBannerClicked', 'true');
    setShowBanner(false);
  };


  if (!showBanner ||  settings.loading || !isLoaded ) {
    return;
  }
  if (showBanner) {
    return (
      <>
          <Link
              href="/how-moneyline-works"
              className="block container relative h-auto mt-3 md:mt-4"
              onClick={handleClick}
          >
              <picture>
                  <source
                      srcSet="/images/how-moneyline-works-mobile.webp"
                      media="(max-width: 767px)"
                  />
                  <source
                      srcSet="/images/how-moneyline-works-tablet.webp"
                      media="(max-width: 1023px)"
                  />
                  <img
                      src="/images/how-moneyline-works-desktop.webp"
                      alt="How Moneyline Works"
                      className="w-full sm:object-cover"
                  />
              </picture>
          </Link>
      </>
    );
  }
}

"use client";
import Icon from "@/components/primitives/icon";
import { LoadingCircle } from "@/components/primitives/loading";

export default function Loading() {
  return (
    <div className="w-screen h-screen relative flex justify-center items-center bg-secondary-300">
      <Icon size="lg" name="moneyline" />
      <div className="absolute inset-0 w-full h-full flex justify-center items-center">
        <LoadingCircle />
      </div>
    </div>
  );
}

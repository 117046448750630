"use client";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import Icon from "@/components/primitives/icon";
import React from "react";
import Toast from "@/components/primitives/toast";
import Button, { BUTTON_VARIANCE } from "../primitives/button";
import { useRoom } from "@/utils/providers/room";
import {
  BettorNotificationModel,
  BettorProfileModel,
  NotificationType,
  formatSport,
  NotificationsActionType,
  Sport,
  ACHIEVEMENTS,
  LinkType,
  MoneylineResponse2,
  BettorActionType,
  BettorAnnouncementType,
} from "common";
import RelativeTime from "../relative-time";
import Avatar from "../primitives/avatar";
import {
  createAchievementSlug,
  createGameDetailSlug,
  createPlayerProfileSlug,
  createProfileSlug,
} from "@/utils/url";
import Link from "next/link";
import assert from "assert";
import { useAuth } from "@clerk/clerk-react";
import mlClient from "@/ml-client";
import * as Popover from "@radix-ui/react-popover";
import ToastProvider from "../primitives/toast-provider";
import { useShellContext } from "@/utils/providers/shell";
import NotFound from "../primitives/not-found";
import { useBettor } from "@/utils/providers/bettor";
import DoubleLogo from "../team/double-logo";
import * as RadixDialog from "@radix-ui/react-dialog";
import Chip from "../primitives/chip";

function Notification({ bettor }: { bettor: BettorProfileModel | null }) {
  if (!bettor) return null;
  const wsData = useRoom();
  const { getToken } = useAuth();
  const [notifications, setNotifications] = React.useState<
    BettorNotificationModel[]
  >([]);
  const [open, setOpen] = React.useState<boolean>(false);

  const [toast, setToast] = React.useState<BettorNotificationModel | null>(
    null
  );
  const [popupQueue, setPopupQueue] = React.useState<BettorNotificationModel[]>(
    []
  );
  console.log("popup queue", popupQueue);
  const [isPopupAnimating, setIsPopupAnimating] = React.useState(false);
  const [toastOpen, setToastOpen] = React.useState<boolean>(false);
  const toastTimerRef = React.useRef(0);
  const [unreadCount, setUnreadCount] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const acknowledgePopup = async (): Promise<void> => {
    if (popupQueue.length === 0) return;
    setIsLoading(true);
    setIsPopupAnimating(true);
    setPopupQueue((prevQueue) => prevQueue.slice(1));
    const popup = popupQueue[0];
    setTimeout(async () => {
      try {
        const token = await getToken();
        await mlClient.post(
          "bettors/notifications",
          {
            type: NotificationsActionType.AcknowledgePopup,
            data: {
              notification_id: popup.id,
            },
          },
          token
        );
      } catch (err) {
        console.error(`Error acknowledging achievement:`, err);
      } finally {
        setIsLoading(false);
        setIsPopupAnimating(false);
      }
    }, 300);
  };

  const updateUnreadCount = (notifications: BettorNotificationModel[]) => {
    const unread = notifications.filter(
      (notification) => !notification.read
    ).length;
    setUnreadCount(unread);
  };

  const markAsRead = async (index: number) => {
    let updatedNotifications = [...notifications];
    updatedNotifications[index].read = true;
    let updatedUnreadCount = unreadCount - 1;
    try {
      const token = await getToken();
      const response = await mlClient.post(
        "bettors/notifications",
        {
          type: NotificationsActionType.MarkAsRead,
          data: { notification_id: updatedNotifications[index].id },
        },
        token
      );
      if (response.status === 200) {
        setNotifications(updatedNotifications);
        setUnreadCount(updatedUnreadCount);
      }
    } catch (err) {
      console.error(`Error marking notification as read:`, err);
    }
  };

  const markAllAsRead = async () => {
    const updatedNotifications = notifications.map((notification) => ({
      ...notification,
      read: true,
    }));
    try {
      const token = await getToken();
      const response = await mlClient.post(
        "bettors/notifications",
        { type: NotificationsActionType.MarkAllAsRead },
        token
      );
      if (response.status === 200) {
        setNotifications(updatedNotifications);
        setUnreadCount(0);
      }
    } catch (err) {
      console.error(`Error marking all notifications as read:`, err);
    }
    setNotifications(updatedNotifications);
    setUnreadCount(0);
  };

  const handleNotificationClick = async (index: number) => {
    await markAsRead(index);
    setNotifications((prevNotifications) => {
      const updatedNotifications = [...prevNotifications];
      updatedNotifications[index] = {
        ...updatedNotifications[index],
        read: true,
      };
      updateUnreadCount(updatedNotifications);
      return updatedNotifications;
    });
    setOpen(false);
  };
  const getNotifications = async () => {
    if (isLoading || notifications.length > 0) return;
    try {
      setIsLoading(true);
      setIsError(false);
      const token = await getToken();
      const response: MoneylineResponse2<{
        notifications: BettorNotificationModel[];
        popups: BettorNotificationModel[];
      }> = await mlClient.get("bettors/notifications", token);
      console.log("notifications", response.data);
      if (response.data) {
        setNotifications(response.data.notifications);
        setPopupQueue(response.data.popups);
        updateUnreadCount(response.data.notifications);
      }
    } catch (err) {
      setIsError(true);
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
      console.error("Error during getNotifications:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const sendToast = (notification: BettorNotificationModel) => {
    setToast(null);
    setToastOpen(false);
    window.clearTimeout(toastTimerRef.current);

    setToast(notification);
    setToastOpen(true);

    toastTimerRef.current = window.setTimeout(() => {
      setToast(null);
      setToastOpen(false);
    }, 5000);
  };

  React.useEffect(() => {
    getNotifications();
    return () => clearTimeout(toastTimerRef.current);
  }, []);

  React.useEffect(() => {
    if (!wsData.roomData) return;
    if (wsData?.roomData.type === "bettor_notifications") {
      const notification: BettorNotificationModel = wsData.roomData.data;
      setNotifications((prevNotifications) => {
        const updatedNotifications = [notification, ...prevNotifications];
        updateUnreadCount(updatedNotifications);
        return updatedNotifications;
      });
      if (notification.popup) {
        setPopupQueue((prevPopupQueue) => {
          const updatedPopupQueue = [notification, ...prevPopupQueue];
          return updatedPopupQueue;
        });
      } else {
        sendToast(notification);
      }
    }
  }, [wsData.roomData]);

  function PopupContent() {
    if (popupQueue.length === 0) return null;
    const popup = popupQueue[0];
    switch (popup.type) {
      case NotificationType.Announcement:
        assert(
          popup.metadata?.announcement,
          "No announcement metadata found for popup."
        );
        const announcement = popup.metadata.announcement;
        switch (announcement.type) {
          case BettorAnnouncementType.NewFeature:
            assert(bettor?.details, "Bettor details not found.");
            return (
              <div
                className={`flex flex-col items-center relative transition-opacity duration-300 ${
                  isPopupAnimating ? "opacity-0" : "opacity-100"
                }`}
              >
                <RadixDialog.Close
                  asChild
                  onClick={() => acknowledgePopup()}
                  className="absolute right-6 top-6"
                >
                  <Button size="sm" variant="circle" color="tertiary">
                    <Icon name="close" size="sm" />
                  </Button>
                </RadixDialog.Close>
                <div
                  className={`h-40 desktop-grid:h-80 w-full bg-center bg-cover desktop-grid:bg-contain bg-no-repeat`}
                  style={{
                    backgroundImage: `url('/images/announcements/${
                      announcement.bg_slug || "new-feature-team-badges"
                    }.webp'`,
                  }}
                ></div>
                <div className="w-full h-full">
                  <div className="flex flex-col p-8 relative bg-secondary-300 gap-y-4">
                    <div className="flex flex-col gap-y-2">
                      <div className="flex justify-center items-center relative -mt-12 mb-4">
                        <Chip color="neutral">
                          <div className="text-label-bold uppercase text-dark">
                            New feature
                          </div>
                          <Icon
                            name="sparkle-group"
                            className="absolute inset-0 fill-primary w-full h-[130%] text-primary"
                          />
                        </Chip>
                      </div>
                      <RadixDialog.Title className="text-white text-headline-bold sm:text-display text-center uppercase italic">
                        {announcement.title}
                      </RadixDialog.Title>
                      <RadixDialog.Description className="text-secondary text-label-lg sm:text-title-lg text-center">
                        {announcement.description}
                      </RadixDialog.Description>
                    </div>
                    <div className="flex justify-center items-center">
                      <Button
                        href={`${createProfileSlug(
                          bettor.details.display_name
                        )}/awards`}
                        onClick={() => acknowledgePopup()}
                      >
                        {announcement?.action_text}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            );
          default:
            throw new Error("This announcement type isn't implemented yet.");
        }
        break;
      case NotificationType.Achievement:
        assert(
          popup.metadata?.achievement,
          "No achievement metadata found for popup."
        );
        const achievementType = popup.metadata.achievement.type;
        const achievement = ACHIEVEMENTS[popup.metadata.achievement.type];
        assert(achievement, "Invalid achievement");
        return (
          <>
            <div className="bg-[url('/images/bettor/achievements/confetti.gif')] bg-center w-full h-full">
              <div
                className={`flex flex-col items-center justify-center px-8 py-14 relative transition-opacity duration-300 ${
                  isPopupAnimating ? "opacity-0" : "opacity-100"
                }`}
              >
                <RadixDialog.Close
                  asChild
                  onClick={() => acknowledgePopup()}
                  className="absolute right-6 top-6"
                >
                  <Button size="sm" variant="circle" color="tertiary">
                    <Icon name="close" size="sm" />
                  </Button>
                </RadixDialog.Close>
                <RadixDialog.Title className="text-secondary text-title-lg-bold sm:text-headline-bold text-center uppercase">
                  Achievement Unlocked
                </RadixDialog.Title>

                <div className="flex justify-center items-center p-8">
                  <div className="text-white text-headline">
                    <img
                      alt={`${achievement.title}`}
                      src={createAchievementSlug(achievementType)}
                      width={150}
                      height={150}
                      className="transition-transform duration-300 transform scale-105"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <RadixDialog.Title className="text-white text-headline-bold sm:text-display text-center uppercase">
                    {achievement.title}
                  </RadixDialog.Title>
                  <RadixDialog.Description className="text-white text-label-lg sm:text-title-lg text-center">
                    {achievement.description}
                  </RadixDialog.Description>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  }

  return (
    <>
      <RadixDialog.Root open={popupQueue.length > 0}>
        <RadixDialog.Portal>
          {/* ROOT */}
          <div className="fixed inset-0 z-50">
            <RadixDialog.Overlay className="bg-black bg-opacity-80 data-[state=open]:animate-overlayShow flex justify-center items-center -z-[1] h-full backdrop-blur-sm">
              {/* SCROLLABLE */}
              <div className="flex justify-center items-center w-full h-full">
                <RadixDialog.Content
                  className={`z-50 m-8 bg-secondary-100 overflow-x-hidden rounded-xl relative shadow-elevation-400 focus:outline-none max-h-[calc(100%_-_64px)] overflow-y-auto border-[2px] border-secondary-300` }
                  style={{
                    width: "600px",
                  }}
                >
                  {popupQueue.length > 0 && (
                    <>
                      <PopupContent />
                    </>
                  )}
                </RadixDialog.Content>
              </div>
            </RadixDialog.Overlay>
          </div>
        </RadixDialog.Portal>
      </RadixDialog.Root>
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger
          className={`${BUTTON_VARIANCE.base} ${BUTTON_VARIANCE.circle.base} ${BUTTON_VARIANCE.circle.size.xs} relative bg-secondary-100 fill-white outline-none group hover:bg-dark`}
          aria-label="Check notifications"
        >
          <Icon name="notification" size="md" />
          {unreadCount > 0 && (
            <div className="rounded-full w-3 h-3 border-[2px] border-secondary-100 bg-primary absolute top-2 right-2 group-hover:border-dark transition-all duration-200 ease-in-out"></div>
          )}
        </Popover.Trigger>
        <Popover.Content
          align="start"
          alignOffset={-24}
          sideOffset={8}
          side="bottom"
          collisionPadding={16}
          className="rounded-md bg-light shadow-elevation-300 z-[50] focus:shadow-elevation-400 will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade max-w-[300px] sm:max-w-[332px] md:max-w-none"
        >
          <div className="p-6 flex flex-row justify-between items-center">
            <div className="flex flex-row items-center text-title-lg-bold sm:text-headline-bold uppercase gap-2 p-0">
              <span>Notifications</span>
              <div
                className={`w-full px-1 h-4 flex rounded-full text-label-lg-bold justify-center items-center ${
                  unreadCount > 0 ? "bg-primary" : "bg-surface-600"
                } text-on-primary`}
              >
                {unreadCount}
              </div>
            </div>
            <div className="card-action p-0">
              <Button
                variant="card"
                onClick={async () => await markAllAsRead()}
              >
                Mark all as read
              </Button>
            </div>
          </div>
          <hr />
          <ScrollArea.Root
            className={`w-full desktop-grid:w-[23.5rem] ${
              notifications.length > 0 ? "h-[26.5rem]" : "h-full"
            }`}
          >
            <ScrollArea.Viewport className="w-full h-full">
              <div className="px-3 py-3">
                {notifications.length > 0 ? (
                  <>
                    {notifications.map((item, i) => (
                      <div onClick={async () => handleNotificationClick(i)}>
                        <Popover.Close asChild>
                          <Item key={i} {...item} isToast={false} />
                        </Popover.Close>
                      </div>
                    ))}
                  </>
                ) : (
                  <NotFound label="No notifications" />
                )}
              </div>
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar
              className="flex select-none touch-none p-0.5 bg-light transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
              orientation="vertical"
            >
              <ScrollArea.Thumb className="flex-1 bg-secondary rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
            </ScrollArea.Scrollbar>
          </ScrollArea.Root>
          {/* {notifications.length > 0 && (
            <div className="flex justify-center items-center py-2">
              <Button
                variant="card"
                onClick={async () => await markAllAsRead()}
              >
                Clear all notifications
              </Button>
            </div>
          )} */}
        </Popover.Content>
      </Popover.Root>
      {/* <Button onClick={() => sendToast(notifications[0])}>
        Trigger Toast
      </Button> */}

      <ToastProvider>
        <Toast.Content
          open={toastOpen}
          onOpenChange={(isOpen) => {
            setToastOpen(isOpen);
            if (!isOpen) {
              window.clearTimeout(toastTimerRef.current);
              setToast(null);
            }
          }}
        >
          {toast ? <Item {...toast} isToast={true} /> : undefined}
        </Toast.Content>
      </ToastProvider>
    </>
  );
}

function Item(props: BettorNotificationModel & { isToast: boolean }) {
  const { setShouldScrollToTop } = useShellContext();
  const { bettor } = useBettor();
  let message: JSX.Element | null = null;
  let link: string | null = null;
  switch (props.type) {
    case NotificationType.Announcement:
      assert(
        props.metadata?.announcement,
        "Missing announcement notification metadata."
      );
      assert(bettor?.details.display_name, "Missing bettor display name.");
      message = (
        <>
          <strong>{props.metadata.announcement.title}</strong>{" "}
          {props.metadata.announcement.description}
        </>
      );
      switch (props.metadata.announcement?.link_type) {
        case LinkType.Profile:
          link = `${createProfileSlug(bettor.details.display_name)}${
            props.metadata.announcement.link_slug
              ? `/${props.metadata.announcement.link_slug}`
              : ""
          }`;
          break;
      }
      break;
    case NotificationType.PicksCreated:
      assert(props.metadata?.bettor, "Missing bettor notification metadata.");
      message = (
        <>
          <strong>{props.metadata.bettor.display_name}</strong> created picks
        </>
      );
      link = createProfileSlug(props.metadata.bettor.display_name);
      break;
    case NotificationType.PicksCanceled:
    case NotificationType.PicksSettled:
      assert(props.metadata?.game, "Missing game notification metadata.");
      message = (
        <>
          Picks for{" "}
          <strong>
            {props.metadata.game.away_team.abbreviation} @{" "}
            {props.metadata.game.home_team.abbreviation}{" "}
          </strong>{" "}
          have been{" "}
          {props.type === NotificationType.PicksCanceled
            ? "canceled"
            : "settled"}
        </>
      );
      link = createGameDetailSlug(
        formatSport(props.metadata.game.sport) as Sport,
        props.metadata.game.id
      );
      break;
    case NotificationType.Follow:
      assert(props.metadata?.bettor, "Missing bettor notification metadata.");
      message = (
        <>
          <strong>{props.metadata.bettor.display_name}</strong> started
          following you
        </>
      );
      link = createProfileSlug(props.metadata.bettor.display_name);
      break;
    case NotificationType.Injury:
      assert(props.metadata?.player, "Missing player notification metadata.");
      message = (
        <>
          Injury Reported - <strong>{props.metadata.player.name}</strong> is{" "}
          {props.metadata.player.status}
        </>
      );
      link = createPlayerProfileSlug(
        formatSport(props.metadata.player.sport) as Sport,
        props.metadata?.player.id
      );
      break;
    case NotificationType.Achievement:
      assert(
        props.metadata?.achievement,
        "Missing achievement notification metadata."
      );
      assert(bettor, "Bettor not available for achievement notification.");
      message = (
        <>
          Achievement unlocked!{" "}
          <strong>
            {ACHIEVEMENTS[props.metadata.achievement.type]?.title}
          </strong>{" "}
        </>
      );
      link = `${createProfileSlug(bettor?.details.username)}/awards`;
      break;
  }
  assert(
    link,
    "Link wasn't built from notification metadata and stopped rendering."
  );

  return (
    <>
      <Link
        href={link}
        scroll={false}
        onClick={() => setShouldScrollToTop(true)}
        className="flex flex-row gap-4 p-3 items-start w-full relative"
      >
        {props.read === false && !props.isToast && (
          <div className="rounded-full w-2 h-2 bg-primary absolute top-4 right-3 group-hover:border-dark transition-all duration-200 ease-in-out"></div>
        )}
        <div className="w-10 h-10 flex justify-center items-center">
          {props.metadata?.announcement ? (
            <Avatar img={"/logos/moneyline.svg"} />
          ) : props.metadata?.game ? (
            <DoubleLogo
              size="sm"
              awayTeam={{
                id: props.metadata.game.away_team.id,
                abbreviation: props.metadata.game.away_team.abbreviation,
                name: props.metadata.game.away_team.name,
                location: props.metadata.game.away_team.location,
                logoUrl: props.metadata.game.away_team.logo_url,
              }}
              homeTeam={{
                id: props.metadata.game.home_team.id,
                abbreviation: props.metadata.game.home_team.abbreviation,
                name: props.metadata.game.home_team.name,
                location: props.metadata.game.home_team.location,
                logoUrl: props.metadata.game.home_team.logo_url,
              }}
            />
          ) : props.metadata?.achievement ? (
            <img
              src={createAchievementSlug(props.metadata.achievement.type)}
              width={32}
              height={32}
            />
          ) : (
            <Avatar
              img={props.metadata?.bettor?.avatar_url}
              border={props.metadata?.player?.team?.primary_color}
            />
          )}
        </div>
        <div className="flex flex-col pr-4 gap-1">
          <div className="text-title desktop-grid:text-title-lg text-dark">
            {message}
          </div>
          <div className="text-label desktop-grid:text-title-sm text-secondary">
            <RelativeTime date={props.created_at} />
          </div>
        </div>
      </Link>
    </>
  );
}
export default Notification;

"use client";

import Icon from "@/components/primitives/icon";
import Button from "@/components/primitives/button";
import Card from "@/components/primitives/card";
import { ApiResponse, GameDetail, HottestGame, Sport } from "common";
import PickGroup from "@/components/pick/group";
import OddsGroup from "../odds/group";
import Badge from "@/components/primitives/badge";
import React, { useEffect, useRef, useState } from "react";
import { useWebSocketMessage } from "@/utils/hooks/websocket-message";
import { createGameDetailSlug } from "@/utils/url";
import Timestamp from "../timestamp";
import GameCardSeriesChip from "./series-chip";
import Image from "next/image";

import { getHottestGame } from "@/utils/game";
import GameCardHottestSkeleton from "@/components/game/card/hottest-skeleton";
import { GameProvider, useGame } from "@/utils/providers/game";

function GameCardHottest({
  sport,
  res,
}: {
  sport?: Sport;
  res?: ApiResponse<HottestGame[]>;
}) {
  const wsHottestGame = useWebSocketMessage("hottest-game");
  console.log("hottest game from ws:", wsHottestGame);


  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [response, setResponse] = React.useState<ApiResponse<
    HottestGame[]
  > | null>(null);

  useEffect(() => {
  //   if (!isVisible) return;

    if (!res) {
      getHottestGame(sport, 1).then((res) => {
        setResponse(res);
        setIsLoading(false);
      });
    } else if (res && res.data) {
      setResponse(res);
      setIsLoading(false);
    }
  }, [res]);

  // useEffect(() => {
  //   if (!blockRef.current) {
  //     console.error("blockRef is not attached to a DOM element.");
  //     return;
  //   }
  // }, [blockRef]);

  const data = response && response.data ? response.data[0] : null;

  return (
    <div>
      {isLoading && <GameCardHottestSkeleton />}
      {data && !isLoading && (
        <GameProvider game={data}>
          <Inner />
        </GameProvider>
      )}
    </div>
  );
}

function Inner() {
  const ctx = useGame();
  return (
    <Button
      unstyled
      href={ctx.slug}
      data-prevent-nprogress={true}
      prefetch={false}
      className="flex relative z-[1]"
    >
      <div className="absolute w-full left-0 right-0 -top-3 flex justify-center z-[4]">
        <div className="flex items-center justify-center">
          {ctx.game.series && (
            <GameCardSeriesChip info={ctx.game.series} neutral />
          )}
        </div>
      </div>
      <div
        className={`flex flex-col overflow-hidden bg-secondary-300 rounded-md card ${
          ctx.game.series ? "pt-5" : "pt-3"
        } pb-2 desktop-grid:pb-5 px-4 w-full h-full relative z-[3]`}
      >
        <div className="flex justify-between items-center flex-wrap desktop-grid:flex-nowrap">
          <div className="whitespace-nowrap gap-1 uppercase text-dark items-center flex text-title-bold text-white pr-4 pb-3">
            <Icon name="flame" gradient="flame" size="sm"></Icon> Hottest Game
          </div>
          <div className="flex gap-2 text-label-sm-medium uppercase text-white pb-3">
            <Badge
              color="white"
              value={ctx.game.totalPicks}
              showZero={true}
              icon={{ name: "flame", gradient: "flame" }}
              label={`${ctx.game.totalPicks === 1 ? "Pick" : "Picks"}`}
            />
            <PickGroup
              dark
              avatars={ctx.game.avatars || []}
              totalBettors={ctx.game.totalBettors || 0}
              totalPicksForSale={ctx.game.totalPicksForSale || 0}
            ></PickGroup>
          </div>
        </div>
        <div className="flex flex-row desktop-grid:flex-col gap-2 flex-wrap justify-center">
          <div className="text-headline-bold text-white uppercase flex items-center justify-center desktop-grid:justify-start gap-1">
            <div>
              <span className="hidden sm:block">
                {ctx.game.awayTeam.location}
              </span>

              <span className="block sm:hidden">
                {ctx.game.awayTeam.abbreviation}
              </span>
            </div>
            <span className="text-title-sm">@</span>
            <div>
              <span className="hidden sm:block">
                {ctx.game.homeTeam.location}
              </span>
              <span className="block sm:hidden">
                {ctx.game.homeTeam.abbreviation}
              </span>
            </div>
          </div>
          <div className="flex justify-center items-center desktop-grid:justify-start">
            <Timestamp
              type="full"
              dateTime={ctx.game.dateTime}
              platform={ctx.game.platform}
              dark
              className="text-label-lg-bold"
            />
          </div>
        </div>
        <div className="flex flex-col order-2 desktop-grid:flex-row gap-4 justify-center desktop-grid:justify-between items-center mt-3 desktop-grid:mt-0 desktop-grid:items-end">
          <div className="hidden desktop-grid:flex order-2 desktop-grid:order-1">
            <span className="text-label-lg text-secondary-700">
              {ctx.game.temperature ? (
                <span>{ctx.game.temperature}°</span>
              ) : undefined}{" "}
              <span>{ctx.game.stadium?.name}</span>
            </span>
          </div>
          <div className="hidden order-1 desktop-grid:flex desktop-grid:order-2 gap-2 items-center">
            <Button
              href={createGameDetailSlug(ctx.game.league, ctx.game.id)}
              color="secondary"
              size="xs"
              forceButton
            >
              View Game
            </Button>
          </div>
        </div>
        {/* FOOTER */}
        <div className="static mt-1 desktop-grid:mt-0 desktop-grid:absolute w-full max-w-[21rem] m-auto inset-0 flex justify-center items-center">
          <div
            className="p-[2px] rounded-md"
            style={{
              background: `linear-gradient(to right, #${ctx.game.awayTeam.primaryColor}, #${ctx.game.homeTeam.primaryColor}), #FFFFFF`,
            }}
          >
            <OddsGroup
              game={ctx.game}
              lockSide="right"
              color="dark"
              record
              startsNow={ctx.startsNow}
            />
          </div>
        </div>
        <div className="pointer-events-none z-[-1] absolute top-[10%] w-[50%] h-full desktop-grid:w-[30rem] desktop-grid:h-[30rem] desktop-grid:-top-full left-0 desktop-grid:left-[10%] opacity-20 flex justify-center items-center">
          <div
            className="w-full h-full absolute"
            style={{
              background: `linear-gradient(270deg, rgba(35, 37, 40, 0) 30%, rgba(35, 37, 40, 1) 100%)`,
            }}
          ></div>
          <Image
            src={ctx.game.awayTeam.logoUrl}
            width={480}
            height={480}
            className="w-full h-full object-contain"
            alt={`${ctx.game.awayTeam.name} Logo Splash`}
          />

          {/*<img*/}
          {/*  src={ctx.game.awayTeam.logoUrl}*/}
          {/*  className="w-full h-full object-contain"*/}
          {/*  alt={`${ctx.game.awayTeam.name} Logo Splash`}*/}
          {/*/>*/}
        </div>
        <div className="pointer-events-none z-[-1] absolute top-[10%] w-[50%] h-full desktop-grid:w-[30rem] desktop-grid:h-[30rem] desktop-grid:-top-full right-0 desktop-grid:right-[10%] opacity-20 flex justify-center items-center">
          <div
            className="w-full h-full absolute"
            style={{
              background: `linear-gradient(90deg, rgba(35, 37, 40, 0) 30%, rgba(35, 37, 40, 1) 100%)`,
            }}
          ></div>

          <Image
            src={ctx.game.homeTeam.logoUrl}
            width={480}
            height={480}
            className="w-full h-full object-contain"
            alt={`${ctx.game.homeTeam.name} Logo Splash`}
          />

          {/*<img*/}
          {/*  src={ctx.game.homeTeam.logoUrl}*/}
          {/*  className="w-full h-full object-contain"*/}
          {/*  alt={`${ctx.game.homeTeam.name} Logo Splash`}*/}
          {/*/>*/}
        </div>
        <Card.Trim
          fromColor={ctx.game.awayTeam.primaryColor}
          toColor={ctx.game.homeTeam.primaryColor}
        />
      </div>
    </Button>
  );
}

export default GameCardHottest;

"use client";
import React, { createContext, useContext, useState, useEffect } from "react";

interface Cooldown {
  isActive: boolean;
  countdown: number;
}
interface CooldownContext {
  getCooldownState: (key: string) => Cooldown;
  startCooldown: (key: string, duration: number) => void;
}

const CooldownContext = createContext<CooldownContext>({
  getCooldownState: () => ({ isActive: false, countdown: 0 }),
  startCooldown: () => {},
});

export const useCooldown = (): CooldownContext => useContext(CooldownContext);

export const CooldownProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [cooldowns, setCooldowns] = useState<Record<string, Cooldown>>({});
  const intervalIdRefs = React.useRef<Record<string, NodeJS.Timeout | null>>(
    {}
  );
  const startCooldown = (key: string, duration: number): void => {
    clearCooldownInterval(key);

    const cooldownDuration = duration;
    const expiresAt = new Date(Date.now() + cooldownDuration);
    localStorage.setItem(`cooldownExpiresAt_${key}`, expiresAt.toISOString());

    setCooldowns((prev) => ({
      ...prev,
      [key]: {
        isActive: true,
        countdown: Math.ceil(duration / 1000),
      },
    }));

    intervalIdRefs.current[key] = setInterval(() => {
      setCooldowns((prev) => {
        const currentCooldown = prev[key];
        if (!currentCooldown) return prev;

        const newCountdown = currentCooldown.countdown - 1;
        if (newCountdown <= 0) {
          clearCooldownInterval(key);
          localStorage.removeItem(`cooldownExpiresAt_${key}`);
          const { [key]: _, ...rest } = prev;
          return rest;
        }
        return {
          ...prev,
          [key]: {
            ...currentCooldown,
            countdown: newCountdown,
          },
        };
      });
    }, 1000);
  };

  const clearCooldownInterval = (key: string) => {
    if (intervalIdRefs.current[key] !== null) {
      clearInterval(intervalIdRefs.current[key]!);
      delete intervalIdRefs.current[key];
    }
  };

  const getCooldownState = (key: string): Cooldown => {
    return cooldowns[key] || { isActive: false, countdown: 0 };
  };

  useEffect(() => {
    const storedCooldowns = Object.keys(localStorage)
      .filter((key) => key.startsWith("cooldownExpiresAt_"))
      .map((key) => {
        const type = key.replace("cooldownExpiresAt_", "");
        const expiresAt = new Date(localStorage.getItem(key) || "");
        if (expiresAt > new Date()) {
          const duration = expiresAt.getTime() - Date.now();
          return { type, duration };
        }
        return null;
      })
      .filter((item): item is { type: string; duration: number } => !!item);

    storedCooldowns.forEach(({ type, duration }) =>
      startCooldown(type, duration)
    );

    const handleStorageChange = (event: StorageEvent) => {
      if (
        event.key?.startsWith("cooldownExpiresAt_") &&
        event.newValue === null
      ) {
        const type = event.key.replace("cooldownExpiresAt_", "");
        clearCooldownInterval(type);
        setCooldowns((prev) => {
          const { [type]: _, ...rest } = prev;
          return rest;
        });
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
      Object.keys(intervalIdRefs.current).forEach(clearCooldownInterval);
    };
  }, []);
  return (
    <CooldownContext.Provider value={{ getCooldownState, startCooldown }}>
      {children}
    </CooldownContext.Provider>
  );
};

"use client";

import React from "react";
import { GameDiscussionCommentModel, ScoreboardGame } from "common";
import { useWebSocketMessage } from "./websocket-message";

type ScoreboardGameDiff = Partial<ScoreboardGame>[];
type ScoreboardInput = ScoreboardGame | ScoreboardGame[];

export function useScoreboardUpdate(
  data: ScoreboardInput,
  setData: React.Dispatch<React.SetStateAction<any>>,
) {
  const wsGames: ScoreboardGameDiff = useWebSocketMessage("scoreboard");
  const wsGamePickUpdates = useWebSocketMessage("game_picks_updated");
  const wsGameCommentUpdates = useWebSocketMessage("game_comments_added");
  console.log("wsgamepickupdates", wsGamePickUpdates);
  console.log("wsgamecommentupdates", wsGameCommentUpdates);

  React.useEffect(() => {
    if (!wsGamePickUpdates || wsGamePickUpdates.length === 0) return;
    const isSingleGame = !Array.isArray(data);
    const dataArray = isSingleGame ? [data] : data;
    let hasNewData = false;
    const updatedData: ScoreboardGame[] = dataArray.map(
      (game: ScoreboardGame) => {
        const update: { gameId: number; totalPicks: number } =
          wsGamePickUpdates.find(
            (gamePickUpdate: { gameId: number; totalPicks: number }) =>
              Number(gamePickUpdate.gameId) === game.id
          );
        if (update) {
          hasNewData = true;
          return {
            ...game,
            totalPicks: update.totalPicks,
          };
        }
        return game;
      }
      
    );
    if (hasNewData) {
      setData(isSingleGame ? updatedData[0] : updatedData);
    }
  }, [wsGamePickUpdates]);

  React.useEffect(() => {
    if (!wsGameCommentUpdates || wsGameCommentUpdates.length === 0) return;
    const isSingleGame = !Array.isArray(data);
    const dataArray = isSingleGame ? [data] : data;
    let hasNewData = false;
    const updatedData: ScoreboardGame[] = dataArray.map(
      (game: ScoreboardGame) => {
        const update: { gameId: number; totalComments: number } =
        wsGameCommentUpdates.find(
            (gameCommentUpdate: { gameId: number; totalPicks: number }) =>
              Number(gameCommentUpdate.gameId) === game.id
          );
        console.log("game comment update", update);
        if (update) {
          hasNewData = true;
          return {
            ...game,
            discussion: {
              ...game.discussion,
              total_comments: update.totalComments
            }
          };
        }
        return game;
      }
      
    );
    if (hasNewData) {
      setData(isSingleGame ? updatedData[0] : updatedData);
    }
  }, [wsGameCommentUpdates]);

  React.useEffect(() => {
    if (typeof window === "undefined" || typeof document === "undefined") return;
    if (!Array.isArray(wsGames) || !data) return;

    let hasNewData = false;
    let statusUpdated = false;

    const isSingleGame = !Array.isArray(data);
    const dataArray = isSingleGame ? [data] : data;

    const updatedData: ScoreboardGame[] = dataArray.map(
      (game: ScoreboardGame) => {
        const update = wsGames.find((wsGame) => wsGame.id === game.id);
        if (update) {
          if (update.status) {
            statusUpdated = true;
          }
          hasNewData = true;
          return updateGameData(game, update);
        }
        return game;
      }
    );

    if (hasNewData) {
      setData(isSingleGame ? updatedData[0] : updatedData);
    }
    // if (statusUpdated) {
    //   setStatusUpdated(true);
    // }
  }, [wsGames]);
}

function updateGameData(
  game: ScoreboardGame,
  update: Partial<ScoreboardGame>
): ScoreboardGame {
  return {
    ...game,
    ...update,
    odds: update.odds ? { ...game.odds, ...update.odds } : game.odds,
    // awayTeam: update.awayTeam
    //   ? { ...game.awayTeam, ...update.awayTeam }
    //   : game.awayTeam,
    // homeTeam: update.homeTeam
    //   ? { ...game.homeTeam, ...update.homeTeam }
    //   : game.homeTeam,
    awayRecord: update.awayRecord
      ? { ...game.awayRecord, ...update.awayRecord }
      : game.awayRecord,
    homeRecord: update.homeRecord
      ? { ...game.homeRecord, ...update.homeRecord }
      : game.homeRecord,
    awayLast10: update.awayLast10
      ? { ...game.awayLast10, ...update.awayLast10 }
      : game.awayLast10,
    homeLast10: update.homeLast10
      ? { ...game.homeLast10, ...update.homeLast10 }
      : game.homeLast10,
    awayStreak: update.awayStreak
      ? { ...game.awayStreak, ...update.awayStreak }
      : game.awayStreak,
    homeStreak: update.homeStreak
      ? { ...game.homeStreak, ...update.homeStreak }
      : game.homeStreak,
    awayInjuries: update.awayInjuries
      ? { ...game.awayInjuries, ...update.awayInjuries }
      : game.awayInjuries,
    homeInjuries: update.homeInjuries
      ? { ...game.homeInjuries, ...update.homeInjuries }
      : game.homeInjuries,
    awayStartingPlayer: update.awayStartingPlayer
      ? { ...game.awayStartingPlayer, ...update.awayStartingPlayer }
      : game.awayStartingPlayer,
    homeStartingPlayer: update.homeStartingPlayer
      ? { ...game.homeStartingPlayer, ...update.homeStartingPlayer }
      : game.homeStartingPlayer,
    startingLineup: {
      away: update.startingLineup?.away
        ? [...(game.startingLineup?.away || []), ...update.startingLineup.away]
        : game.startingLineup?.away || [],
      home: update.startingLineup?.home
        ? [...(game.startingLineup?.home || []), ...update.startingLineup.home]
        : game.startingLineup?.home || [],
    },
    status: update.status !== undefined ? update.status : game.status,
    period: update.period !== undefined ? update.period : game.period,
    totalPicks: update.totalPicks ? update.totalPicks : game.totalPicks,
    totalComments: update.totalComments
      ? update.totalComments
      : game.totalComments,
    // discussion: update.discussion
    //   ? {
    //       ...game.discussion,
    //       ...update.discussion,
    //     }
    //   : game.discussion,
  };
}

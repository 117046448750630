"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sanitizeSmallIntFlags = exports.DEFAULT_SMALL_INT_FLAGS = exports.VALID_SMALL_INT_MASK = exports.toggleFlag = exports.isFlagEnabled = exports.disableFlag = exports.enableFlag = void 0;
const enableFlag = (currentFlags, flag) => {
    return currentFlags | flag;
};
exports.enableFlag = enableFlag;
const disableFlag = (currentFlags, flag) => {
    return currentFlags & ~flag;
};
exports.disableFlag = disableFlag;
const isFlagEnabled = (currentFlags, flag) => {
    return (currentFlags & flag) === flag;
};
exports.isFlagEnabled = isFlagEnabled;
const toggleFlag = (currentFlags, flag) => {
    return currentFlags ^ flag;
};
exports.toggleFlag = toggleFlag;
exports.VALID_SMALL_INT_MASK = (1 << 16) - 1;
exports.DEFAULT_SMALL_INT_FLAGS = 32767;
const sanitizeSmallIntFlags = (flags) => {
    return flags & exports.VALID_SMALL_INT_MASK;
};
exports.sanitizeSmallIntFlags = sanitizeSmallIntFlags;

"use client";
import React, {useRef, useState} from "react";
import Button from "../primitives/button";
import Card from "../primitives/card";
import Form from "../primitives/form";
import { LoadingCircle } from "../primitives/loading";
import Icon from "../primitives/icon";
import Image from "next/image";

export default function Newsletter() {
  const blockRef : React.RefObject<HTMLDivElement> = useRef(null);

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess(false);

    const controller = new AbortController();
    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 8000);

    try {
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/newsletter/enroll`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
          signal: controller.signal,
        }
      );

      clearTimeout(timeoutId);
      if (res.ok) {
        setSuccess(true);
        setLoading(false);
      }
      if (!res.ok) {
        throw new Error("Failed to subscribe");
      }
      setError("Failed to subscribe");
    } catch (error) {
      setError("Failed to subscribe");
    }
    setLoading(false);
  };

  // Check is block within viewport


  return (
    <>
      <Card custom={true} dark className="py-14 px-6">
        {/*<div*/}
        {/*  className="absolute inset-0 z-10 bg-cover bg-no-repeat bg-center bg-opacity-10 overflow-hidden"*/}
        {/*  style={{ backgroundImage: "url(/images/hoop.png)" }}*/}
        {/*></div>*/}
        {
          <Image
            src='/images/hoop.png'
            width={416}
            height={458}
            className="w-[100%] h-[100%] object-cover absolute inset-0 z-10"
            alt="Remain in the action"
          />
        }
        {/* <div className="absolute inset-0 z-20 bg-dark-widget-overlay opacity-60"></div> */}
        <Form
          onSubmit={handleSubmit}
          className="relative z-30 text-white text-center flex flex-col items-center gap-6"
        >
          <div ref={blockRef} className="w-[60px] h-[2px] bg-[#AEE449] mx-auto"></div>
          <div className="uppercase text-display wrap-balance">
            Remain in the action
          </div>
          <p className="text-base leading-7">
            Join the Moneyline newsletter to receive news, updates, discover top
            pickers, and more. Get everything you'll need for all of your
            upcoming games!
          </p>
          <div className="w-full">
            {loading && (
              <div className="h-[120px] flex justify-center items-center">
                <LoadingCircle />
              </div>
            )}
            {!loading && success && (
              <div className="min-h-[120px] flex flex-row gap-2 justify-center items-center ">
                <div
                  className={`relative inline-flex justify-center items-center rounded-full p-1 bg-success-700`}
                >
                  <Icon
                    name={"checkmark"}
                    className={"fill-success-300 color-success-300"}
                    size="xs"
                  />
                </div>
                <div className="text-success">
                  You have successfully subscribed!
                </div>
              </div>
            )}
            {!loading && !success && (
              <div className="flex flex-col gap-6">
                <Form.Field name="email" className="block">
                  <Form.Input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email"
                    required
                    className="block bg-white rounded-lg border border-black-1 px-4 py-3 text-black-1 placeholder:text-[#A3A8B1] leading-6 w-full focus:outline-none focus:ring-2 focus:ring-black-1 focus:border-transparent"
                  />
                  <Form.Message match="valueMissing" className="text-white">
                    Please fill out this field.
                  </Form.Message>
                </Form.Field>
                <Form.Submit variant="filled">Join Newsletter</Form.Submit>
              </div>
            )}
          </div>
          <div className="text-label-sm">
            By subscribing, you agree to our{" "}
            <a href="/privacy-policy" className="underline">
              Privacy Policy
            </a>
          </div>
        </Form>
      </Card>
    </>
  );
}

export function FooterNewsletter() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess(false);

    const controller = new AbortController();
    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 8000);

    try {
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/newsletter/enroll`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
          signal: controller.signal,
        }
      );

      clearTimeout(timeoutId);
      if (res.ok) {
        setSuccess(true);
        setLoading(false);
      }
      if (!res.ok) {
        throw new Error("Failed to subscribe");
      }
      setError("Failed to subscribe");
    } catch (error) {
      setError("Failed to subscribe");
    }
    setLoading(false);
  };

  return (
    <div className="relative">
      {loading && (
        <div className="h-[120px] flex justify-center items-center absolute mx-auto w-full">
          <LoadingCircle />
        </div>
      )}
      {!loading && success && (
        <div className="min-h-[120px] flex flex-col gap-6 justify-center items-center absolute mx-auto w-full">
          <div
            className={`relative inline-flex justify-center items-center rounded-full p-4 bg-success-700`}
          >
            <Icon
              name={"checkmark"}
              className={"fill-success-300 color-success-300"}
              size="xl"
            />
          </div>
          <div className="text-success">You have successfully subscribed!</div>
        </div>
      )}

      <Form
        onSubmit={handleSubmit}
        className={`flex flex-row flex-wrap gap-2 ${
          !loading && !success ? "" : "invisible"
        }`}
      >
        <div className="flex flex-col gap-2 items-center w-full">

        <Form.Field name="email" className="w-full">
          <Form.Input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            required
            />
          <Form.Message match="patternMismatch">
            Please enter a valid email.
          </Form.Message>
        </Form.Field>
        <Form.Submit variant="filled" className="max-w-[96px]">Sign up</Form.Submit>
            </div>
      </Form>
    </div>
  );
}

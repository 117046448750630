"use client";
import React, { createContext, useContext, useState, useEffect } from "react";
import { BettorProfileModel } from "common";
import { useUser } from "@clerk/nextjs";
import { usePathname } from "next/navigation";
import { useAuth } from "@clerk/clerk-react";
import { getBettorProfile } from "../bettor";

interface BettorContextType {
  bettor: BettorProfileModel | null;
  setBettor: (bettor: BettorProfileModel | null) => void;
}

const BettorContext = createContext<BettorContextType>({
  bettor: null,
  setBettor: () => {},
});

export const useBettor = () => {
  const context = useContext(BettorContext);
  if (!context) {
    throw new Error("useBettor must be used within a BettorProvider");
  }
  return context;
};

export function BettorProvider({
  bettor: initialBettor,
  children,
}: {
  bettor: BettorProfileModel | null;
  children: React.ReactNode;
}) {
  const pathname = usePathname();
  const [bettor, setBettor] = useState<BettorProfileModel | null>(
    initialBettor
  );

  const { isSignedIn, user, isLoaded } = useUser();
  const { getToken } = useAuth();

  useEffect(() => {
    console.log("user changed", bettor, isLoaded, isSignedIn);
    if (isLoaded && isSignedIn) {
      if (user.id) {
        (async () => {
          try {
            const token = await getToken();
            const bettorData: BettorProfileModel = await getBettorProfile("clerk_id", user.id, token ? token : undefined);
            setBettor(bettorData);
          } catch (error) {
            console.error("Error getting bettor data", error);
            setBettor(null);
          }
        })();
      } else {
        setBettor(null);
      }
    }
  }, [user, isLoaded, isSignedIn]);

  return (
    <BettorContext.Provider value={{ bettor, setBettor }}>
      {children}
    </BettorContext.Provider>
  );
}

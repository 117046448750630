import mlClient from "@/ml-client";
import { BettorIdentifier, BettorProfileModel, BettorRelationshipsModel, BettorRelationshipType, PaginatedResponse } from "common";

export async function getBettorProfile(type: "username" | "clerk_id", value: string, token?: string): Promise<BettorProfileModel> {
  const result = await mlClient.get(`bettors/${type}/${type === "username" ? value.toLowerCase() : value}/profile`, token);
  return result.data;
}

export async function getBettorRelationships(id: BettorIdentifier, relationshipType: BettorRelationshipType, token?: string): Promise<PaginatedResponse<BettorRelationshipsModel>> {
  const result = await mlClient.get(`bettors/${id.type}/${id.value[0]}/relationships/${relationshipType}`, token);
  return result.data;
}

export async function deactivateSelf(token: string): Promise<boolean> {
  const result = await mlClient.post(`bettors`, {type: "deactivate"}, token);
  return result.data;
}
export async function resetSelfStats(token: string): Promise<boolean> {
  const result = await mlClient.post(`bettors`, {type: "reset-stats"}, token);
  return result.data;
}

import React, { HTMLAttributes } from "react";

// Status indicator.

export interface ChipProps {
  color: ChipColorVariant;
  children: React.ReactNode;
  size?: ChipSizeVariant;
}
type ChipSizeVariant = "sm" | "md" | "lg";
type ChipColorVariant = "primary" | "white" | "neutral" | "dark" | "success" | "error";
type MappedChipColor = {
  [key in ChipColorVariant]: string;
};
type MappedChipSize = {
  [key in ChipSizeVariant]: string;
};
type ChipVariance = {
  color: MappedChipColor;
  size: MappedChipSize;
};
const variance: ChipVariance = {
  color: {
    primary: "bg-primary text-on-primary fill-on-primary",
    white: "bg-surface text-secondary-300",
    neutral: "bg-surface-600 text-secondary-200",
    dark: "bg-[#303236] text-white fill-white",
    success: "bg-success-700 text-on-success-700 fill-on-success-700",
    error: "bg-error-700 text-on-error-700 fill-on-error-700",
  },
  size: {
    sm: "text-label-sm-bold px-4 py-1 min-w-[2.875rem] h-[1.6875rem] rounded-full",
    md: "text-label-sm-bold xs:text-title-sm-medium px-2 py-1 min-w-[4rem] xs:min-w-[5rem] h-[1.46rem] xs:h-[1.6875rem] rounded-full",
    lg: "text-label-sm-bold xs:text-title-lg-medium px-4 py-1 min-w-[4rem] xs:min-w-[5rem] h-[1.46rem] xs:h-[1.6875rem] rounded-full",
  },
};

function Chip(props: ChipProps) {
  const classes: string = `gap-1 inline-flex jusfify-center items-center text-center ${
    variance.color[props.color]
  } ${variance.size[props.size || "md"]}`;
  return (
    <div className={classes}>
      <div className="w-full">{props.children}</div>
    </div>
  );
}

export default Chip;

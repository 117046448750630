import React from "react";
import Icon from "./icon";

export default function NotFound({
  icon,
  label,
  caption,
}: {
  icon?: string;
  label: string;
  caption?: string;
}) {
  return (
    <div className="flex flex-col w-full h-full py-4 gap-2 justify-center items-center">
      {icon && <Icon name={icon} size="2xl" className="fill-secondary-700" />}
      <div className="text-headline-lg uppercase text-secondary-700 text-center">
        {label}
      </div>
      <div className="text-caption text-secondary-700">{caption}</div>
    </div>
  );
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RANKS = exports.Rank = exports.unlockAllAchievements = exports.ACHIEVEMENTS = exports.AchievementType = void 0;
var AchievementType;
(function (AchievementType) {
    AchievementType[AchievementType["Informed"] = 1] = "Informed";
    AchievementType[AchievementType["BackAtIt"] = 2] = "BackAtIt";
    AchievementType[AchievementType["Genesis"] = 3] = "Genesis";
    AchievementType[AchievementType["JackOfAllPicks"] = 4] = "JackOfAllPicks";
    AchievementType[AchievementType["Bookie"] = 5] = "Bookie";
    AchievementType[AchievementType["ShowingSomeLove"] = 6] = "ShowingSomeLove";
    AchievementType[AchievementType["LeadTheWay"] = 7] = "LeadTheWay";
    AchievementType[AchievementType["HeatingUp"] = 8] = "HeatingUp";
    AchievementType[AchievementType["ThatsCold"] = 9] = "ThatsCold";
    AchievementType[AchievementType["FirstSale"] = 10] = "FirstSale";
    AchievementType[AchievementType["FirstPurchase"] = 11] = "FirstPurchase";
    AchievementType[AchievementType["BigBaller"] = 12] = "BigBaller";
    AchievementType[AchievementType["DiamondHands"] = 13] = "DiamondHands";
    AchievementType[AchievementType["Royalty"] = 14] = "Royalty";
    AchievementType[AchievementType["Seriously"] = 15] = "Seriously";
    AchievementType[AchievementType["FirstPick"] = 16] = "FirstPick";
    AchievementType[AchievementType["TOTAL_ACHIEVEMENTS"] = 17] = "TOTAL_ACHIEVEMENTS";
})(AchievementType || (exports.AchievementType = AchievementType = {}));
exports.ACHIEVEMENTS = {
    [AchievementType.Informed]: {
        title: "Informed",
        description: "Read a news article.",
        slug: "informed"
    },
    [AchievementType.BackAtIt]: {
        title: "Back At It",
        description: "Login 5 consecutive days.",
        slug: "back-at-it"
    },
    [AchievementType.Genesis]: {
        title: "Genesis",
        description: "Create your first pick.",
        slug: "genesis"
    },
    [AchievementType.JackOfAllPicks]: {
        title: "Jack of all Picks",
        description: "Create a pick for all available sports leagues.",
        slug: "jack-of-all-picks"
    },
    [AchievementType.Bookie]: {
        title: "Bookie",
        description: "Visit any sportsbook after creating or buying a pick.",
        slug: "bookie"
    },
    [AchievementType.ShowingSomeLove]: {
        title: "Showing Some Love",
        description: "Follow 10 bettors.",
        slug: "showing-some-love"
    },
    [AchievementType.LeadTheWay]: {
        title: "Lead the Way",
        description: "Gain 10 followers.",
        slug: "lead-the-way"
    },
    [AchievementType.HeatingUp]: {
        title: "Heating Up",
        description: "Win 5 picks in a row.",
        slug: "heating-up"
    },
    [AchievementType.ThatsCold]: {
        title: "That's Cold",
        description: "Lose 5 picks in a row.",
        slug: "thats-cold"
    },
    [AchievementType.FirstSale]: {
        title: "First Sale",
        description: "Sell your first pick.",
        slug: "first-sale"
    },
    [AchievementType.FirstPurchase]: {
        title: "First Purchase",
        description: "Purchase a pick.",
        slug: "first-purchase"
    },
    [AchievementType.BigBaller]: {
        title: "Big Baller",
        description: "Earn $1000 on Moneyline.",
        slug: "big-baller"
    },
    [AchievementType.DiamondHands]: {
        title: "Diamond Hands",
        description: "Earn a first place badge for any sport/team.",
        slug: "diamond-hands"
    },
    [AchievementType.Royalty]: {
        title: "Royalty",
        description: "Win a bet with +500 odds or more.",
        slug: "royalty"
    },
    [AchievementType.Seriously]: {
        title: "Seriously?",
        description: "Lose a bet with -500 odds or more.",
        slug: "seriously"
    },
    [AchievementType.FirstPick]: {
        title: "First Pick",
        description: "Create your first pick.",
        slug: "first-pick"
    },
};
const unlockAllAchievements = () => {
    return Object.keys(exports.ACHIEVEMENTS).map((key) => ({
        type: parseInt(key),
        created_at: "",
        seen: false,
    }));
};
exports.unlockAllAchievements = unlockAllAchievements;
var Rank;
(function (Rank) {
    Rank[Rank["Diamond"] = 1] = "Diamond";
    Rank[Rank["Platinum"] = 2] = "Platinum";
    Rank[Rank["Gold"] = 3] = "Gold";
    Rank[Rank["Silver"] = 4] = "Silver";
    Rank[Rank["Bronze"] = 5] = "Bronze";
})(Rank || (exports.Rank = Rank = {}));
exports.RANKS = {
    [Rank.Diamond]: "diamond",
    [Rank.Platinum]: "platinum",
    [Rank.Gold]: "gold",
    [Rank.Silver]: "silver",
    [Rank.Bronze]: "bronze",
};

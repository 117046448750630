"use client";
import React, { useState, useEffect } from "react";

function formatRelativeTime(date: Date, short?: boolean) {
  const delta = Date.now() - date.getTime();
  const absoluteDelta = Math.abs(delta);
  const seconds = absoluteDelta / 1000;
  if (seconds < 5) {
    return "Just now";
  }
  if (seconds < 60) {
    return short
      ? `${Math.round(seconds)}s ago`
      : `${Math.round(seconds)} second${Math.round(seconds) === 1 ? "" : "s"} ago`;
  }
  const minutes = seconds / 60;
  if (minutes < 60) {
    return short
      ? `${Math.round(minutes)}m ago`
      : `${Math.round(minutes)} minute${Math.round(minutes) === 1 ? "" : "s"} ago`;
  }
  const hours = minutes / 60;
  if (hours < 24) {
    return short
      ? `${Math.round(hours)}h ago`
      : `${Math.round(hours)} hour${Math.round(hours) === 1 ? "" : "s"} ago`;
  }
  const days = hours / 24;
  if (days < 7) {
    return short ? `${Math.round(days)}d ago` : `${Math.round(days)} day${Math.round(days) === 1 ? "" : "s"} ago`;
  }
  const weeks = days / 7;
  if (weeks < 4.3) {
    return short
      ? `${Math.round(weeks)}w ago`
      : `${Math.round(weeks)} week${Math.round(weeks) === 1 ? "" : "s"} ago`;
  }
  const months = days / 30.44;
  if (months < 12) {
    return short
      ? `${Math.round(months)}mo ago`
      : `${Math.round(months)} month${Math.round(months) === 1 ? "" : "s"} ago`;
  }

  const years = days / 365;
  return short ? `${Math.round(years)}y ago` : `${Math.round(years)} year${Math.round(years) === 1 ? "" : "s"} ago`;
}

function RelativeTime({ date, short }: { date: string; short?: boolean }) {
  const [relativeTime, setRelativeTime] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const localDate = new Date(date);
      if (isNaN(localDate.getTime())) {
        console.error(`Invalid date passed: ${date}`);
        setRelativeTime("Invalid date");
        return;
      }
      setRelativeTime(formatRelativeTime(localDate, short));
    };
    updateTime();
    const intervalId = setInterval(updateTime, 60000);

    return () => clearInterval(intervalId);
  }, [date]);

  return <span>{relativeTime}</span>;
}

export default RelativeTime;

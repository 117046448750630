import { TeamBasic } from "common";
import { TeamDisplaySize } from "./display";
import TeamLogo from "./logo";

export default function DoubleLogo({homeTeam, awayTeam, size}: {homeTeam: TeamBasic, awayTeam: TeamBasic, size: TeamDisplaySize}) {
    return (
      <div className="w-[45px] h-[33px] relative mb-1">
            <div
              style={{
                clipPath: "polygon(0 0, 63% 0, 35% 100%, 0 100%)",
              }}
              className="absolute inset-0"
            >
              <TeamLogo
                custom
                team={homeTeam} 
                size={size}
                className="h-[31px] w-[31px] z-[1] absolute left-0"
              />
            </div>
            <svg
              width="12"
              height="31"
              viewBox="0 0 12 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute left-0 right-0 mx-auto z-10"
            >
              <path d="M11.5 0.5L0.5 30" stroke="#A3A8B1" strokeLinecap="round" />
            </svg>
  
            <div
              style={{
                clipPath: "polygon(63% 0, 100% 0, 100% 100%, 35% 100%)",
              }}
              className="absolute inset-0"
            >
              <TeamLogo
                custom
                team={awayTeam}
                size={size}
                className="h-[31px] w-[31px] z-[1] absolute right-0 top-px"
              />
            </div>
          </div>
    )
  }
  
export * from "./odds";
export * from "./numbers";
export * from "./date";
export * from "./debug";
export * from "./numbers";

export async function getClientIp(headers: any) {
  const headersList = await headers();
    const FALLBACK_IP_ADDRESS = '0.0.0.0';
    const forwardedFor = headersList.get('x-forwarded-for');
    if (forwardedFor) {
      return forwardedFor.split(',')[0] ?? FALLBACK_IP_ADDRESS;
    }
    return headersList.get('x-real-ip') ?? FALLBACK_IP_ADDRESS;
  }

  export function notApplicable(value: any): { label: string; value: any } {
    return {
      label: "N/A",
      value,
    };
  }
  
  export function empty(): string {
    return `- -`;
  }
  
  export function tagline() {
    return " | Moneyline";
  }
  
  export function randomProfileImage(): string {
    const urlPrefix =
      'https://moneyline-profile-images.s3.us-west-1.amazonaws.com/';
  
    const images = [
      'default_baseball-alien.png',
      'default_baseball-dreamy.png',
      'default_baseball-fox.png',
      'default_baseball-grunge.png',
      'default_baseball-lizard.png',
      'default_baseball-trippy.png',
      'default_basketball-alien.png',
      'default_basketball-dreamy.png',
      'default_basketball-giraffe.png',
      'default_basketball-grunge.png',
      'default_basketball-leopard.png',
      'default_basketball-panda.png',
      'default_basketball-rabbit.png',
      'default_basketball-trippy.png',
      'default_football-alien.png',
      'default_football-gorilla.png',
      'default_football-grunge.png',
      'default_football-lion.png',
      'default_football-trippy.png',
      'default_hockey-alien.png',
      'default_hockey-dreamy.png',
      'default_hockey-penguin.png',
      'default_hockey-polar-bear.png',
      'default_hockey-shark.png',
      'default_hockey-trippy.png',
    ];
  
    return urlPrefix + images[Math.floor(Math.random() * images.length)];
  }

  export function scrollToSection(id: string, offset?: number) {
    const section = document.getElementById(id);
    if (section) {
        const yOffset: number = offset ? -offset : -60;
        const y = section.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };
"use client";
import * as RadixTabs from "@radix-ui/react-tabs";
import { useRouter } from "next/navigation";
import Icon from "@/components/primitives/icon";
import Button from "@/components/primitives/button";
import { Option } from "@/components/primitives/select";

export interface TabsProps extends RadixTabs.TabsProps {
  label: string;
  items: TabItem[];
  variant?: "pill" | "dark";
  block?: boolean;
  buttonWidth?: number;
}

export interface TabItem extends Option {
  content?: JSX.Element;
}

function ScoreboardSportsTabs(props: TabsProps) {
  const { block, label, items, variant, buttonWidth, ...rest } = props;
    return (
      <RadixTabs.Root {...rest} className={`${props.block ? "w-full" : undefined}`}>
        <div className={`w-full inline-flex flex-row bg-secondary-700 rounded-lg items-center justify-center p-1 h-9 min-[360px]:h-11`}>
          <RadixTabs.List aria-label={props.label} className="flex w-full">
            {props.items.map((item, i) => (
              <RadixTabs.Trigger
                key={i}
                value={item.value}
                className={`text-title-medium !text-[0.725rem] min-[360px]:!text-[0.9375rem] uppercase py-2 px-2 h-7 min-[360px]:h-9 rounded-[8px] bg-transparent ease-in-out duration-200 data-[state=active]:bg-white hover:bg-white/50 whitespace-nowrap flex justify-center items-center w-full`}
              >
                <span className={`${(item.count && item.count > 0) ? "text-secondary-300" : "text-secondary-400"}`}>{item.label} {item.count ? `(${item.count})` : undefined}</span>
              </RadixTabs.Trigger>
            ))}
          </RadixTabs.List>
        </div>
        {props.items.map((item, i) => (
          <RadixTabs.Content key={i} value={item.value}>
            {item.content}
          </RadixTabs.Content>
        ))}
      </RadixTabs.Root>
    );
}

export default ScoreboardSportsTabs;
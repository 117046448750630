"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bettorLeaderboardOrderMap = exports.bettorMetricsTimeframeToKey = exports.defaultBettorMetricsTimeframeContext = exports.BettorAchivementType = exports.BettorActionType = exports.BettorNotificationType = exports.BettorSubscriptionType = exports.BettorAnnouncementType = exports.LinkType = exports.NotificationType = exports.NotificationsActionType = exports.INVALID_BETTOR_RELATIONSHIP_TYPE = exports.isBettorRelationshipType = exports.INVALID_BETTOR_IDENTIFIER_TYPE = exports.isBettorIdentifierType = exports.MAX_EXP_MONTHS = exports.MAX_EXP_YEARS = exports.MAX_EMAIL_LENGTH = exports.MAX_BIO_LENGTH = exports.MAX_NAME_LENGTH = exports.MAX_DOB_LENGTH = exports.MAX_USERNAME_LENGTH = exports.MAX_URL_LENGTH = void 0;
exports.MAX_URL_LENGTH = 2000;
exports.MAX_USERNAME_LENGTH = 18;
exports.MAX_DOB_LENGTH = 25;
exports.MAX_NAME_LENGTH = 50;
exports.MAX_BIO_LENGTH = 500;
exports.MAX_EMAIL_LENGTH = 320;
exports.MAX_EXP_YEARS = 3000;
exports.MAX_EXP_MONTHS = 12;
const isBettorIdentifierType = (input) => {
    switch (input) {
        case "id":
        case "clerk_id":
        case "username":
            return true;
        default:
            return false;
    }
};
exports.isBettorIdentifierType = isBettorIdentifierType;
exports.INVALID_BETTOR_IDENTIFIER_TYPE = "Invalid bettor identifier type. Only id, clerk_id or username are accepted.";
const isBettorRelationshipType = (input) => {
    switch (input) {
        case "following":
        case "followers":
            return true;
        default:
            return false;
    }
};
exports.isBettorRelationshipType = isBettorRelationshipType;
exports.INVALID_BETTOR_RELATIONSHIP_TYPE = "Invalid bettor relationship type. Only following or followers are accepted.";
var NotificationsActionType;
(function (NotificationsActionType) {
    NotificationsActionType[NotificationsActionType["MarkAsRead"] = 0] = "MarkAsRead";
    NotificationsActionType[NotificationsActionType["MarkAllAsRead"] = 1] = "MarkAllAsRead";
    NotificationsActionType[NotificationsActionType["MarkAsUnread"] = 2] = "MarkAsUnread";
    NotificationsActionType[NotificationsActionType["MarkAllAsUnread"] = 3] = "MarkAllAsUnread";
    NotificationsActionType[NotificationsActionType["AcknowledgePopup"] = 4] = "AcknowledgePopup";
})(NotificationsActionType || (exports.NotificationsActionType = NotificationsActionType = {}));
var NotificationType;
(function (NotificationType) {
    NotificationType[NotificationType["Announcement"] = 0] = "Announcement";
    NotificationType[NotificationType["Email"] = 1] = "Email";
    NotificationType[NotificationType["SMS"] = 2] = "SMS";
    NotificationType[NotificationType["Discord"] = 4] = "Discord";
    NotificationType[NotificationType["WhatsApp"] = 8] = "WhatsApp";
    NotificationType[NotificationType["Telegram"] = 16] = "Telegram";
    NotificationType[NotificationType["Push"] = 32] = "Push";
    NotificationType[NotificationType["Follow"] = 64] = "Follow";
    NotificationType[NotificationType["Injury"] = 128] = "Injury";
    NotificationType[NotificationType["PicksCreated"] = 256] = "PicksCreated";
    NotificationType[NotificationType["PicksSettled"] = 512] = "PicksSettled";
    NotificationType[NotificationType["PicksCanceled"] = 513] = "PicksCanceled";
    NotificationType[NotificationType["PicksSold"] = 1024] = "PicksSold";
    NotificationType[NotificationType["NewsArticle"] = 2048] = "NewsArticle";
    NotificationType[NotificationType["UpcomingGame"] = 4096] = "UpcomingGame";
    NotificationType[NotificationType["Achievement"] = 8192] = "Achievement";
    NotificationType[NotificationType["Badge"] = 16384] = "Badge";
    NotificationType[NotificationType["Leaderboard"] = 32768] = "Leaderboard";
})(NotificationType || (exports.NotificationType = NotificationType = {}));
var LinkType;
(function (LinkType) {
    LinkType[LinkType["Profile"] = 0] = "Profile";
})(LinkType || (exports.LinkType = LinkType = {}));
var BettorAnnouncementType;
(function (BettorAnnouncementType) {
    BettorAnnouncementType[BettorAnnouncementType["NewFeature"] = 0] = "NewFeature";
    BettorAnnouncementType[BettorAnnouncementType["Maintenance"] = 1] = "Maintenance";
})(BettorAnnouncementType || (exports.BettorAnnouncementType = BettorAnnouncementType = {}));
var BettorSubscriptionType;
(function (BettorSubscriptionType) {
    BettorSubscriptionType[BettorSubscriptionType["Follow"] = 0] = "Follow";
    BettorSubscriptionType[BettorSubscriptionType["Unfollow"] = 1] = "Unfollow";
    BettorSubscriptionType[BettorSubscriptionType["SourceFollow"] = 2] = "SourceFollow";
    BettorSubscriptionType[BettorSubscriptionType["SourceUnfollow"] = 3] = "SourceUnfollow";
    BettorSubscriptionType[BettorSubscriptionType["Injury"] = 4] = "Injury";
    BettorSubscriptionType[BettorSubscriptionType["PicksCreated"] = 5] = "PicksCreated";
    BettorSubscriptionType[BettorSubscriptionType["PicksSettled"] = 6] = "PicksSettled";
    BettorSubscriptionType[BettorSubscriptionType["Sport"] = 7] = "Sport";
})(BettorSubscriptionType || (exports.BettorSubscriptionType = BettorSubscriptionType = {}));
var BettorNotificationType;
(function (BettorNotificationType) {
    BettorNotificationType[BettorNotificationType["App"] = 0] = "App";
    BettorNotificationType[BettorNotificationType["Sms"] = 1] = "Sms";
    BettorNotificationType[BettorNotificationType["Discord"] = 2] = "Discord";
    BettorNotificationType[BettorNotificationType["Email"] = 3] = "Email";
})(BettorNotificationType || (exports.BettorNotificationType = BettorNotificationType = {}));
var BettorActionType;
(function (BettorActionType) {
    BettorActionType[BettorActionType["Follow"] = 0] = "Follow";
    BettorActionType[BettorActionType["Unfollow"] = 1] = "Unfollow";
    BettorActionType[BettorActionType["NotificationsOn"] = 2] = "NotificationsOn";
    BettorActionType[BettorActionType["NotificationsOff"] = 3] = "NotificationsOff";
    BettorActionType[BettorActionType["Deactivate"] = 4] = "Deactivate";
    BettorActionType[BettorActionType["Reactivate"] = 5] = "Reactivate";
    BettorActionType[BettorActionType["Update"] = 6] = "Update";
    BettorActionType[BettorActionType["Create"] = 7] = "Create";
    BettorActionType[BettorActionType["Mute"] = 8] = "Mute";
    BettorActionType[BettorActionType["Unmute"] = 9] = "Unmute";
    BettorActionType[BettorActionType["AcknowledgeAchievement"] = 10] = "AcknowledgeAchievement";
})(BettorActionType || (exports.BettorActionType = BettorActionType = {}));
var BettorAchivementType;
(function (BettorAchivementType) {
    BettorAchivementType[BettorAchivementType["FirstPick"] = 0] = "FirstPick";
})(BettorAchivementType || (exports.BettorAchivementType = BettorAchivementType = {}));
function defaultBettorMetricsTimeframeContext() {
    return {
        wins: 0,
        losses: 0,
        ties: 0,
        units: 0,
        rank: 0,
        win_rate: 0,
    };
}
exports.defaultBettorMetricsTimeframeContext = defaultBettorMetricsTimeframeContext;
function bettorMetricsTimeframeToKey(input) {
    switch (input) {
        case "allTime":
            return "at";
        case "yesterday":
            return "l1d";
        case "last7Days":
            return "l7d";
        case "last30Days":
            return "l30d";
        case "pastYear":
            return "l365d";
        case "last10Games":
            return "l10g";
        case "last25Games":
            return "l25g";
        case "last25GamesLast25Days":
            return "l25g_l25d";
        case "last100Games":
            return "l100g";
        case "last100GamesLast100Days":
            return "l100g_l100d";
    }
}
exports.bettorMetricsTimeframeToKey = bettorMetricsTimeframeToKey;
exports.bettorLeaderboardOrderMap = {
    wins_asc: "wins ASC",
    wins_desc: "wins DESC",
    losses_asc: "losses ASC",
    losses_desc: "losses DESC",
    ties_asc: "ties ASC",
    ties_desc: "ties DESC",
    win_rate_asc: "win_rate ASC",
    win_rate_desc: "win_rate DESC",
    total_picks_asc: "total_picks ASC",
    total_picks_desc: "total_picks DESC",
    total_units_gained_asc: "total_units_gained ASC",
    total_units_gained_desc: "total_units_gained DESC",
};

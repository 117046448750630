"use client";
import Button, { BUTTON_VARIANCE } from "@/components/primitives/button";
import {
  GameAlignmentType,
  GameStatus,
  LineType,
  Odd2,
  RecentPick,
  ScoreboardGame,
  TeamBasic,
  isGameClosed,
  isGameException,
  lineToPickType,
  toSportType,
  buildOdd,
} from "common";
import { formatOdd } from "@/utils";
import Link from "next/link";
import React, { useRef } from "react";
import TeamLogo from "@/components/team/logo";
import {
  getInPossession,
} from "@/utils/game";
import flip from "./flip.module.css";
import { CreatedPickTooltip } from "@/components/primitives/tooltip";
import GameCardSeriesChip from "./series-chip";
import Icon from "@/components/primitives/icon";
import { useOddsFormat } from "@/utils/providers/odds-format";
import PickChip from "../detail/pick-chip";
import { usePickSlip } from "@/components/pick/create-pick";
import { useGame } from "@/utils/providers/game";
import GameTimestamp from "../timestamp";
// import { usePickSlip } from "@/utils/providers/pick-slip";

export interface GameCardCompactProps
  extends React.HTMLAttributes<HTMLElement> {
  displayingOdds: LineType;
  view: "slider" | "grid";
}
function GameCardCompact(props: GameCardCompactProps) {
  console.log("compact card re-render");
  const ctx = useGame();
  const [flipped, setFlipped] = React.useState<boolean>(false);
  const [prevStatus, setPrevStatus] = React.useState<GameStatus>(
    ctx.game.status
  );
  const [prevId, setPrevId] = React.useState<number | undefined>(undefined);
  const [delayStatus, setDelayStatus] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (
      prevStatus !== undefined &&
      prevStatus !== ctx.game.status &&
      ctx.game.id === prevId
    ) {
      setFlipped(true);
      setDelayStatus(true);

      const timer = setTimeout(() => {
        setPrevStatus(ctx.game.status);
        setDelayStatus(false);
      }, 800);

      return () => clearTimeout(timer);
    } else {
      setPrevId(ctx.game.id);
      setPrevStatus(ctx.game.status);
    }
  }, [ctx.game.status, ctx.game.id, prevStatus, prevId]);

  React.useEffect(() => {
    if (flipped) {
      const timer = setTimeout(() => {
        setFlipped(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [flipped]);


  // Loading Team logo when it is within viewport


  return (
    <Link
      href={ctx.slug}
      data-prevent-nprogress={true}
      prefetch={false}
      className="relative"
    >
      <div
        className={`${
          props.view === "grid"
            ? "scale-[0.80] min-[370px]:scale-[0.825] min-[380px]:scale-[0.85] min-[390px]:scale-[0.875] min-[400px]:scale-[0.9] min-[410px]:scale-[0.925] min-[420px]:scale-[0.95] min-[440px]:scale-100"
            : undefined
        } ${
          props.view === "slider" ? "mr-4" : undefined
        } origin-top-left w-[200px] h-[132px] rounded-xl ${flip.card}`}
      >
        <div
          className={`${flip.cardInner} ${flipped ? flip.flipped : undefined}`}
        >
          <div
            className={`${flip.cardFront} ${
              ctx.closed ? "bg-final" : "bg-surface shadow-card"
            } p-4 rounded-xl min-w-[200px] h-[132px] whitespace-nowrap inline-block hover:shadow-elevation-400 hover:bg-surface transition-all duration-300 ease-in-out cursor-pointer`}
          >
            <div className={`${flip.cardContent} inset-0 p-4`}>
              <div className="absolute w-full left-0 right-0 -top-3 flex justify-center">
                <div className="flex items-center justify-center">
                  {ctx.game.series && (
                    <GameCardSeriesChip
                      info={ctx.game.series}
                      neutral={ctx.closed}
                    />
                  )}
                </div>
              </div>
              <div className="right-0 -bottom-3 left-0 absolute flex justify-center">
                {/* <div className="flex shadow-elevation-200 rounded-full">
                  <Badge
                    color="white"
                    value={ctx.game.totalPicks}
                    showZero={true}
                    icon={
                      ctx.game.totalPicks && ctx.game.totalPicks > 0
                        ? { name: "flame", gradient: "flame" }
                        : undefined
                    }
                    label={`Pick${ctx.game.totalPicks != 1 ? "s" : ""}`}
                  />
                </div> */}
                <PickChip
                  totalPicks={ctx.game.totalPicks}
                  color="white"
                  totalComments={ctx.game.discussion?.total_comments || 0}
                  showAvatars={false}
                  avatars={[]}
                  totalPicksForSale={0}
                  totalBettors={0}
                />
              </div>
                  <GameTimestamp type="compact" dateTime={ctx.game.dateTime} platform={ctx.game.platform} ctx={ctx} />
        
              <div className="flex flex-col gap-1">
                {/* AWAY ROW */}
                <div
                  className={`flex justify-between items-center gap-2 border-b-[0.6px] ${
                    (ctx.live || ctx.startsNow) && !delayStatus
                      ? "border-secondary-700"
                      : "border-transparent"
                  }`}
                >
                  <div className="flex justify-between items-center gap-2 px-1">
                    <TeamLogo size="sm" team={ctx.game.awayTeam} />
                    <div className="flex flex-col">
                      <div className="text-title">
                        {ctx.game.awayTeam.abbreviation || "N/A"}
                      </div>
                      {ctx.game.status !== "InProgress" && !ctx.startsNow && !delayStatus && (
                        <div className="relative text-grey text-label-sm">
                          <div className="left-0 absolute">
                            {ctx.game.league === "nhl" ? (
                              <>
                                {ctx.game.awayRecord.wins}-
                                {ctx.game.awayRecord.losses}-
                                {ctx.game.awayRecord.overtimeLosses}
                              </>
                            ) : (
                              <>
                                {ctx.game.awayRecord.wins}-
                                {ctx.game.awayRecord.losses}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="relative flex justify-center items-center">
                    {/* {ctx.live && (
                        <div className="absolute w-px h-5 bg-secondary-700"></div>
                      )} */}

                    <CompactOddsCell
                      alignment="away"
                      winner={
                        ctx.winner === "away" ? ctx.game.awayTeam : undefined
                      }
                      status={delayStatus ? prevStatus : ctx.game.status}
                      score={ctx.game.awayScore}
                      game={ctx.game}
                      odd={buildOdd(
                        props.displayingOdds,
                        GameAlignmentType.Away,
                        ctx.game.odds
                      )}
                      againstOdd={buildOdd(
                        props.displayingOdds,
                        GameAlignmentType.Home,
                        ctx.game.odds
                      )}
                      startsNow={ctx.startsNow}
                    />
                  </div>
                </div>
                {/* HOME ROW */}
                <div className="flex justify-between items-center gap-2">
                  <div className="flex justify-between items-center gap-2 px-1">
                    <TeamLogo size="sm" team={ctx.game.homeTeam} />
                    <div className="flex flex-col">
                      <div className="text-title">
                        {ctx.game.homeTeam.abbreviation || "N/A"}
                      </div>
                      {ctx.game.status !== "InProgress" && !ctx.startsNow && !delayStatus && (
                        <div className="relative text-grey text-label-sm">
                          <div className="left-0 absolute">
                            {ctx.game.league === "nhl" ? (
                              <>
                                {ctx.game.homeRecord.wins}-
                                {ctx.game.homeRecord.losses}-
                                {ctx.game.homeRecord.overtimeLosses}
                              </>
                            ) : (
                              <>
                                {ctx.game.homeRecord.wins}-
                                {ctx.game.homeRecord.losses}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="relative flex justify-center items-center">
                    {/* {ctx.live && (
                        <div className="absolute w-px h-5 bg-secondary-700"></div>
                      )} */}
                    <CompactOddsCell
                      alignment="home"
                      winner={
                        ctx.winner === "home" ? ctx.game.homeTeam : undefined
                      }
                      status={delayStatus ? prevStatus : ctx.game.status}
                      score={ctx.game.homeScore}
                      odd={buildOdd(
                        props.displayingOdds,
                        GameAlignmentType.Home,
                        ctx.game.odds
                      )}
                      againstOdd={buildOdd(
                        props.displayingOdds,
                        GameAlignmentType.Away,
                        ctx.game.odds
                      )}
                      game={ctx.game}
                      startsNow={ctx.startsNow}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${flip.cardBack} rounded-xl`}>
            <Icon name="moneyline" size="xl" />
            {/* <Lottie
                key={Date.now()}
                loop={false}
                animationData={animationData}
                play={flipped}
                style={{ width: 100, height: 100 }}
              /> */}
          </div>
        </div>
      </div>
    </Link>
  );
}

interface CompactOddsCellProps {
  status: GameStatus;
  winner?: TeamBasic | null;
  score?: number;
  odd: Odd2 | undefined;
  againstOdd: Odd2 | undefined;
  game: ScoreboardGame;
  alignment: "away" | "home";
  startsNow?: boolean;
  onClick?: () => void;
}

function CompactOddsCell(props: CompactOddsCellProps) {
  const { oddsFormat } = useOddsFormat();
  const [prevScore, setPrevScore] = React.useState<number | undefined>(
    undefined
  );
  const [currentScore, setCurrentScore] = React.useState<number | undefined>(
    props.score
  );
  const [animateScore, setAnimateScore] = React.useState(false);

  React.useEffect(() => {
    if (props.score !== undefined && props.score !== currentScore) {
      if (currentScore !== undefined && props.score !== currentScore) {
        setAnimateScore(true);
        setTimeout(() => setAnimateScore(false), 500);
      }
      setPrevScore(currentScore);
      setCurrentScore(props.score);
    }
  }, [props.score]);

  const pickAnimationElement = useRef<HTMLDivElement>(null);
  const { addPick, getPickStatus, changePickAlignment, removePick } = usePickSlip();
  const [addingPick, setAddingPick] = React.useState(false);
  const odd =
    props.odd === undefined ||
    props.odd.value === 0 ||
    props.odd.value === null ||
    props.odd.payout === null
      ? undefined
      : formatOdd(oddsFormat, props.odd);
  // console.log("odd from compact cell", props.odd);
  const exception = isGameException(props.status);
  const closed = isGameClosed(props.status);
  const addPickDetails: RecentPick | undefined =
    props.odd && props.againstOdd
      ? {
          type: lineToPickType(props.odd.type, props.odd.alignment),
          alignment: props.odd.alignment,
          sport_type: toSportType(props.game.league),
          game: {
            global_game_id: props.game.id,
            date_time_utc: props.game.dateTime,
            status: props.game.status,
            platform: props.game.platform,
            teams: {
              0: {
                id: props.game.awayTeam.id,
                name: props.game.awayTeam.name,
                location: props.game.awayTeam.location,
                abbreviation: props.game.awayTeam.abbreviation,
                primary_color: props.game.awayTeam.primaryColor,
                logoUrl: props.game.awayTeam.logoUrl,
                slug: `${props.game.awayTeam.location}-${props.game.awayTeam.name}`,
              },
              1: {
                id: props.game.homeTeam.id,
                name: props.game.homeTeam.name,
                location: props.game.homeTeam.location,
                abbreviation: props.game.homeTeam.abbreviation,
                primary_color: props.game.homeTeam.primaryColor,
                logoUrl: props.game.homeTeam.logoUrl,
                slug: `${props.game.homeTeam.location}-${props.game.homeTeam.name}`,
              },
            },
          },
          odds: {
            0: props.alignment === "away" ? props.odd : props.againstOdd,
            1: props.alignment === "home" ? props.odd : props.againstOdd,
          },
        }
      : undefined;

  const pickStatus = getPickStatus(addPickDetails);

  const disabled =
    props.status === "InProgress" ||
    closed ||
    !odd ||
    pickStatus.created ||
    pickStatus.opposing.created ||
    props.startsNow;

  function handlePickClick(e: any) {
    e.preventDefault();
    e.stopPropagation();
    console.log("pick status:", pickStatus);

    if (pickStatus.created) {
      return;
    } else if (pickStatus.added) {
      removePick(addPickDetails);
      return;
    } else if (pickStatus.opposing.added) {
      changePickAlignment(addPickDetails);
      return;
    } else if (!pickStatus.added && !pickStatus.opposing.added) {
      addPick(addPickDetails, pickAnimationElement);

      setAddingPick(true);
      setTimeout(() => {
        setAddingPick(false);
        return;
      }, 700);
    }
  }

  const inPossession = getInPossession(props.game, props.alignment);
  return (
    <div className="relative" ref={pickAnimationElement}>
      {inPossession && (
        <div className="top-4 bg-secondary-300 w-1 h-1 rounded-full absolute -right-1"></div>
      )}

      <Button
        custom
        disabled={disabled}
        className={`flex rounded-md relative z-0 overflow-hidden gap-1 items-center p-2 justify-center text-secondary-200 group ${
          props.status === "InProgress" || !odd || props.startsNow
            ? "bg-transparent text-title-bold pl-0 pr-0 justify-between"
            : closed && props.winner
            ? "bg-dark text-title-bold text-light"
            : closed && !props.winner
            ? "bg-transparent text-title-bold"
            : pickStatus.created || pickStatus.opposing.created
            ? "bg-transparent text-title-bold"
            : `${BUTTON_VARIANCE.standard.color.filled.secondary} bg-surface-600 text-title-bold`
        } w-[5.0625rem] h-9 data-[disabled="true"]:bg-transparent ${
          (pickStatus.added || addingPick) && props.status === "Scheduled"
            ? "!bg-primary"
            : ""
        }
        ${disabled ? "pointer-events-none" : ""}
        `}
        data-disable-nprogress={true}
        onClick={handlePickClick}
      >
        {odd === undefined ? (
          <div className="w-full text-center text-label-lg-bold text-secondary-600">
            TBD
          </div>
        ) : props.status === "Scheduled" || exception ? (
          <>
            <span className="normal-case">
              {odd.type === 0 ? odd.payout : odd?.value}
            </span>
            <div
              className={`text-grey text-label-lg-bold group-hover:text-secondary-200 ${
                (pickStatus.added || addingPick) && props.status === "Scheduled"
                  ? "text-secondary-200"
                  : ""
              }`}
            >
              {odd.type === 0 ? undefined : odd.payout}
            </div>
          </>
        ) : (props.status === "InProgress" || props.startsNow) ? (
          <>
            <div className="flex justify-between w-full items-center relative">
              <span className="normal-case">
                {odd.type === 0 ? odd.payout : odd?.value}
              </span>
              <div className="w-px h-5 bg-secondary-700"></div>
              <div className="relative flex justify-center items-center w-6 h-[1.15rem]">
                {animateScore ? (
                  <>
                    <div
                      className={`text-title-bold w-6 absolute inset-0 animate-exitToTop z-10`}
                    >
                      {prevScore}
                    </div>
                  </>
                ) : (
                  <>
                    {/* <div className={`text-title-bold w-6`}>{currentScore}</div> */}
                    <div
                      className={`text-title-bold w-6 absolute inset-0 animate-enterFromBottom z-10`}
                    >
                      {currentScore}
                    </div>
                  </>
                )}
                {/* <span className="text-title-bold w-6 absolute inset-0">{currentScore}</span> */}

                {/* <span className="text-title-bold w-6">{currentScore}</span> */}
              </div>
            </div>
          </>
        ) : (
          <>
            {props.winner ? (
              <>
                <TeamLogo
                  custom
                  className="-top-[125%] -left-1/4 -z-[1] absolute opacity-25 w-[150%] max-w-none pointer-events-none"
                  team={props.winner}
                />
                <span className="text-light">{props.score}</span>
              </>
            ) : (
              <span className="text-dark">{props.score}</span>
            )}
          </>
        )}
      </Button>
      {pickStatus.created && props.status === "Scheduled" && (
        <CreatedPickTooltip>You picked this odd</CreatedPickTooltip>
      )}
    </div>
  );
}
function Placeholder({ view }: { view: "slider" | "grid" }) {
  return (
    <div
      className={`flex flex-col gap-2 bg-surface shadow-elevation-400 mr-4 p-4 rounded-xl min-w-[200px] h-[132px] ${
        view === "grid"
          ? "scale-[0.80] min-[370px]:scale-[0.825] min-[380px]:scale-[0.85] min-[390px]:scale-[0.875] min-[400px]:scale-[0.9] min-[410px]:scale-[0.925] min-[420px]:scale-[0.95] min-[440px]:scale-100"
          : undefined
      } ${
        view === "slider" ? "mr-4" : undefined
      } origin-top-left w-[200px] h-[132px] rounded-xl`}
    >
      <div className="flex justify-between">
        <div className="bg-surface-400 rounded-[10px] w-16 h-[14px] animate-pulse"></div>
        <div className="bg-surface-400 rounded-[10px] w-10 h-[14px] animate-pulse"></div>
      </div>
      <div className="flex justify-between gap-2">
        <div className="bg-surface-400 rounded-full w-9 h-9 animate-pulse"></div>
        <div className="bg-surface-400 rounded-[10px] w-10 h-9 animate-pulse"></div>
        <div className="bg-surface-400 rounded-[10px] w-20 h-9 animate-pulse"></div>
      </div>
      <div className="flex justify-between gap-2">
        <div className="bg-surface-400 rounded-full w-9 h-9 animate-pulse"></div>
        <div className="bg-surface-400 rounded-[10px] w-10 h-9 animate-pulse"></div>
        <div className="bg-surface-400 rounded-[10px] w-20 h-9 animate-pulse"></div>
      </div>
    </div>
  );
}
GameCardCompact.Placeholder = Placeholder;
export default GameCardCompact;

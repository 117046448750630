"use client";
import React from "react";
import * as RadixPopover from '@radix-ui/react-popover';
import Icon from "./icon";

export interface TooltipProps extends RadixPopover.PopoverProps {
  variant?: "default" | "dark";
}
function Tooltip(props: TooltipProps) {
  return (
    <RadixPopover.Root {...props}>
      <RadixPopover.Trigger className={`inline-flex justify-center items-center ml-1`}>
        <Icon name="info" size="sm" className={`${props.variant === "dark" ? "fill-white/50" : "fill-secondary"}`}></Icon>
      </RadixPopover.Trigger>
      <Content>{props.children}</Content>
    </RadixPopover.Root>
  );
}

function Content(props: RadixPopover.PopoverContentProps) {
  return (
    <RadixPopover.Portal>
      <RadixPopover.Content
        sideOffset={8}
        side="top"
        {...props}
        className="no-hint data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade text-dark select-none rounded-md bg-surface p-3 z-[50] text-title-sm-medium text-center shadow-elevation-300 will-change-[transform,opacity] max-w-[250px]"
      >
        {props.children}
        <RadixPopover.Arrow className="fill-surface" />
      </RadixPopover.Content>
    </RadixPopover.Portal>
  );
}

// export function TooltipProvider(props: RadixPopover.TooltipProviderProps) {
//   return (
//     <RadixPopover.Provider {...props}>{props.children}</RadixPopover.Provider>
//   );
// }

export interface CreatedPickTooltipProps extends TooltipProps {
  position?: "top" | "bottom";
  gameInProgress?: boolean;
}

export function CreatedPickTooltip(props: CreatedPickTooltipProps) {
  return (
    <RadixPopover.Root {...props}>
      <RadixPopover.Trigger
        className={`absolute flex justify-center items-center z-10
          ${
            props.position && props.position === "top"
              ? "w-[22px] h-[22px] rounded-full bg-secondary-300 top-[-20px] left-0 right-0 mx-auto"
              : props.position === "bottom"
              ? "w-[22px] h-[22px] rounded-full bg-secondary-300 bottom-[-20px] left-0 right-0 mx-auto"
              : "right-[-8px] top-0 bottom-0 my-auto"
          }
        `}
      >
        <Icon
          name={props.gameInProgress ? "checkmark" : "lock"}
          color={props.position ? "primary" : "dark"}
          size="xs"
        />
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          sideOffset={8}
          className="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade text-dark select-none rounded-md bg-surface p-3 text-title-sm-medium text-center shadow-elevation-300 will-change-[transform,opacity] max-w-[250px] relative z-50"
        >
          {props.children}
          <RadixPopover.Arrow className="fill-surface" />
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
}

export default Tooltip;

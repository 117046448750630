"use client";
import { formatDate, formatTime } from "@/utils";
import { GameStatus, Sport } from "common";
import React, { HTMLAttributes } from "react";
import LiveIndicator from "./live-indicator";
import { useCountdown } from "@/utils/hooks/countdown";
import { buildGameCountdown, formatGameStatus, LiveGame } from "@/utils/game";
import { GameContextType } from "@/utils/providers/game";
// import { useGame } from "@/utils/providers/game";

export type GameTimestampVariant = "compact" | "stacked" | "full";

export interface GameTimestampProps extends HTMLAttributes<HTMLDivElement> {
  type: GameTimestampVariant;
  dateTime: string;
  platform: string;
  dark?: boolean;
  delayStatus?: boolean;
  ctx?: GameContextType;
}

export default function GameTimestamp(props: GameTimestampProps) {
  // const ctx = useGame();
  const activeCountdown = !props.ctx?.live && !props.ctx?.closed;
  const countdown = useCountdown(props.dateTime, undefined, undefined, activeCountdown);
  let gameCountdown =
    countdown !== null ? buildGameCountdown(countdown) : null;

    React.useEffect(() => {
      if (!gameCountdown || !props.ctx?.setShowCountdown || !props.ctx?.setStartsNow) return;
      props.ctx.setShowCountdown(gameCountdown.showCountdown);
      props.ctx.setStartsNow(gameCountdown.startsNow);
    }, [gameCountdown?.showCountdown, gameCountdown?.startsNow]);

    // gameCountdown = { countdown: "STARTS NOW", startsNow: true, showCountdown: true }
  const classes: string = `inline-flex flex-row items-center uppercase gap-1 ${
    props.dark ? "text-white" : "text-dark"
  } ${props.className}`;
  if (props.type === "full") {
    return (
      <div className={classes}>
        {props.ctx?.live ? (
          <LiveIndicator />
        ) : props.dateTime ? (
          <>
            {gameCountdown?.showCountdown ? (
              <>
              <span>{gameCountdown.message}</span>
              </>
            ) : (
              <>
                <span>{formatDate(props.dateTime, true, true, true)}</span>
                <span>•</span>
                <span>{formatTime(props.dateTime, true)}</span>
              </>
            )}
          </>
        ) : (
          <span>N/A</span>
        )}
        <span>/</span>
        <span>{props.platform || "N/A"}</span>
      </div>
    );
  } else if (props.type === "stacked") {

    return (
      <div className={classes}>
        {props.dateTime ? (
          <>
            <div className="flex flex-col gap-y-1 justify-center items-center">
              <div className="text-title-lg-bold text-dark flex items-center gap-1">
                {props.ctx?.live ? (
                  <LiveIndicator />
                ) : gameCountdown?.showCountdown ? (
                  <>
                      <span>{gameCountdown.message}</span>
                  </>
                ) : (
                  formatTime(props.dateTime, true)
                )}{" "}
                / {props.platform || "N/A"}
              </div>
              {!props.ctx?.live && (
                <span
                  className={`text-body-sm ${
                    props.dark ? "text-secondary-700" : "text-secondary-400"
                  }`}
                >
                  {formatDate(props.dateTime, true, true, true)}
                </span>
              )}
            </div>
          </>
        ) : (
          <span>N/A</span>
        )}
      </div>
    );
  } else if (props.type === "compact") {
    if (!props.ctx) return null;
    return (
      <div
                className={`flex justify-between items-center gap-4 text-grey text-label-lg-medium uppercase mb-2`}
              >
                <div className="flex items-center gap-1">
                  <div className="flex items-center gap-2">
                    {(props.ctx.live && !props.delayStatus) ? (
                      <>
                        {/* <div className="indicator-live" /> */}
                        <div className="inline-flex normal-case">
                          {props.ctx.game.league === "mlb" ? (
                            <div className="flex items-center gap-2">
                              {`${props.ctx.live.status}, ${props.ctx.live.period.balls || 0}-${
                                props.ctx.live.period.strikes || 0
                              }, ${props.ctx.live.period.outs || 0} Out`}
                              <div className="relative h-full w-[12px]">
                                <div className="absolute inset-0 top-[3px] inline-flex gap-[2px] flex-row flex-wrap w-[12px] rotate-45">
                                  <div
                                    className={`w-[5px] h-[5px] ${
                                      props.ctx.live.period.onSecond
                                        ? "bg-primary-500"
                                        : "bg-secondary-700"
                                    }`}
                                  ></div>
                                  <div
                                    className={`w-[5px] h-[5px] ${
                                      props.ctx.live.period.onFirst
                                        ? "bg-primary-500"
                                        : "bg-secondary-700"
                                    }`}
                                  ></div>
                                  <div
                                    className={`w-[5px] h-[5px] ${
                                      props.ctx.live.period.onThird
                                        ? "bg-primary-500"
                                        : "bg-secondary-700"
                                    }`}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            props.ctx.live.status
                          )}
                        </div>
                        {props.ctx.game.league === "nfl" && props.ctx.live && props.ctx.driveStatus ? (
                          <div className="normal-case flex flex-row justify-center items-center gap-2">
                            <div className={`w-px h-3 bg-secondary-700`}></div>
                            <div>{props.ctx.driveStatus.downAndDistance}</div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : props.ctx.closed ? (
                      <span className="text-dark text-label-lg-bold uppercase">
                        {formatGameStatus(props.ctx.game)}
                      </span>
                    ) : (
                      <>
                        {(gameCountdown && gameCountdown.showCountdown) ? (
                          <>
                              <span>
                                {gameCountdown.message}
                              </span>
                          </>
                        ) : (
                          <span>
                            {formatTime(props.ctx.game.dateTime, true)}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {props.ctx?.live && !props.delayStatus ? (
                  <LiveIndicator />
                ) : (
                  <div>{props.platform}</div>
                )}
              </div>
    )
  }
}
